@import "../../../../../assets/sass/variables"
.address
  div,
  span
    margin-bottom: 15px
    color: $black
  .label
    color: $dark-grey
    font-size: 13px
    margin-bottom: 5px

