@import "../../assets/sass/variables"
.my-orders-page-wrapper
  .page-title
    padding: 30px 0 0 0
    margin: auto
    max-width: 1200px
    h2
      display: inline-block
      margin-top: 0
      font-size: 24px
      line-height: 29px
      font-weight: 600
      color: $black
    .btn-help
      background-image: $information
      background-repeat: no-repeat
      background-position: 0 center
      margin-left: 15px
      padding: 0 0 0  23px
      color: $blue
      top: -3px

    .btn-help:hover
      color: $blue-hover
    .btn-help:active
      color: $blue-pressed
    .btn-help:disabled
      color: $blue-disabled
    .search-address-wrapper
      margin: 20px 0
    .rs-input-group
      .btn
        font-weight: bold
      .btn::before
        display: none

    .pagination-wrapper
      margin-top: 20px


@media screen and (max-width: 1220px)
  .my-orders-page-wrapper
    .page-title
      margin: 0 10px

@media screen and (max-width: 860px)
  .my-orders-page-wrapper
    main
      overflow: auto
    .page-title
      padding: 15px 15px 0
      margin: 0 !important
    .rs-input-group
      flex-wrap: wrap
      .rs-input
        margin-bottom: 10px 
        height: 45px
      .btn
        width: 100%!important
        margin: 0
