@import "../../assets/sass/variables"
.product-wrapper-tile
  background: #FFF
  height: 100%
  padding: 15px
  margin: 5px 0
  .img
    height: 126px
    overflow: hidden
    width: 100%
    img
      height: 100%
      margin: 0 auto
      width: auto
      max-width: 100%
  .h3
    margin: 10px 0
    color: $black
    font-size: 14px
    overflow: hidden
    max-height: 54px
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    white-space: pre-wrap
    a
      color: $black
      display: block
      height: 40px
      text-decoration: none
    a:hover
      color: $blue-hover
    a:active
      color: $blue-pressed
    a:disabled
      color: $blue-disabled
  .product-info_title
    color: $dark-grey
    font-size: 12px
    white-space: nowrap
  .value
    color: $black
    font-size: 14px
    white-space: nowrap
  .value.date
    font-size: 12px
  .center
    border-left: 1px solid
    border-right: 1px solid
    border-color: $light-grey
    text-align: center
    .value
      font-weight: 600
  .product-info
    margin-bottom: 15px
    width: 100%
  .product-info.info-v2
    display: flex
    text-align: center
    .product-info_title
      white-space: normal
      width: 100%
    .product-info-price,
    .product-info-left
      .product-info_title
        margin-bottom: 6px
    .value
      width: 100%
  .product-info.info-v2>div
    align-content: flex-end
    display: flex
    flex-wrap: wrap

  .product-action
    display: flex
    gap: 10px
    .btn-usual
      border: 2px solid $blue
      color: $black
      box-sizing: border-box
      font-size: 12px
      height: 40px
      width: 100%
      min-width: 78px
  .product-action-add_reserve
    flex: 1 1 auto
    .btn-usual
      color: $black
  .product-action-add_cart
    flex: 1 1 auto
    .btn-usual
      background: $blue
      border: 2px solid $blue
      color: $white
  .product-action-compare
    display: flex
    align-items: center
    .compare:disabled
      background: $compare-grey no-repeat left 50%
  .removeFromCompare
    background: $remove no-repeat 50% 50%
    height: 18px
    width: 18px
    position: absolute
    top: 15px
    right: 15px
  .removeFromCompare:hover
    background: $remove-blue no-repeat 50% 50%
.sticky-row
  z-index: 10
  .name,
  .product-info
    opacity: 0
    height: 0
    visibility: hidden
    margin: 0
  .img
    height: 10px

