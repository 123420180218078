@import "../../assets/sass/variables"
.return-page-wrapper
  .react-tabs__tab-list
    border: none
    background: $light-grey-2
    margin: 0
    height: 30px
    display: flex
    align-items: center
  .react-tabs__tab
    line-height: 30px
    font-size: 24px
    font-weight: 600
    color: $blue
    padding: 0
    bottom: 0
    vertical-align: middle
    border: none
    background-color: $light-grey-2

  .react-tabs__tab:first-child
    background: $slash no-repeat right 15px center
    padding-right: 56px
  .react-tabs__tab--selected
    border: none
    color: $black
    background-color: $light-grey-2!important
  .react-tabs__tab--selected:after
    display: none
  .search-address-wrapper
    .btn::before
      display: inline-block!important
      padding-left: 23px

  .table-responsive
    .rs-table-cell-wrap
      color: $black!important
      font-size: 12px
  .rs-table-row-header
    .rs-table-cell-header:first-child, .rs-table-cell-header:last-child
      .rs-table-cell-content
        color: $black!important




