@import "../../assets/sass/variables"
.tile
  .show-grid
    height: calc(100vh - 280px)
    overflow-y: auto
.tile-wrapper
  height: 500px
  overflow-y: auto
  .rs-flex-box-grid-top
    align-items: stretch

@media screen and (max-width: 1470px)
  .tile
    .rs-col-md-6
      width: 33.3333%
@media screen and (max-width: 1240px)
  .tile
    .rs-col-md-6
      width: 50%
@media screen and (max-width: 860px)
  .tile
    .rs-col-md-6
      width: 100%