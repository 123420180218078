@import "../../assets/sass/variables"
.profile-shipping-address-item
    display: flex
    flex-wrap: nowrap
    padding: 15px 12px 15px 8px
    background-color: $white
    align-items: center
    margin-bottom: 6px
    color: $black
    font-size: 12px
    min-height: 55px
    justify-content: space-between
    
    .logo
        margin-right: 5px
        width: 24px
    .storage
      width: 180px
    .address
      // flex: 1 1 auto
      width: calc( 100% - 560px )
      padding: 0 15px
    .info
      width: 140px
      padding: 0 15px
      color: $dark-grey
    .edit
      width: 190px
      padding: 0 15px
      .btn-edit
        margin-bottom: 0
    .delete
      width: 26px
      .btn-delete
        background-position: right

@media screen and (max-width: 860px)
  .profile-shipping-address-item
    flex-wrap: wrap
    position: relative
    padding: 20px
    .logo
      width: 24px
      margin-bottom: 15px
    .storage
      width: calc( 100% - 29px )   
      margin-bottom: 15px   
    .address
      width: 100%
      padding: 0 25px 15px 0
    .info
      width: 50%
      padding: 0
    .edit
      width: 50%
      padding: 0
    .delete
      width: 18px
      position: absolute
      right: 20px
      .rs-btn
        padding: 0


