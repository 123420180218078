@import "../../assets/sass/variables"
h5
  height: 50px
  overflow: hidden
.comparisonPage
  .breadcrumbs-wrapper
    margin-bottom: 15px

  main
    background: $light-grey-2
    display: block
    height: 100vh
    padding: 60px 20px 80px 20px
  .product-wrapper
    height: auto
  
  .comparison-tile-product
    background: $light-grey-2
    min-width: calc( 100vw - 42px ) 
  .comparison-tile-product,
  .comparison-property-product
    >div
      min-width: 224px
  .compare-body-products
    height: calc(100vh - 250px)
    overflow: auto
    .product-info
      margin-left: 0
    .center
      border-right: none
    .product-info-price
      padding-left: 15px!important
  .compare-body-products.sticky
    padding-bottom: 60px
  .comparison-property-product
    min-height: 84px
    display: flex
    flex-wrap: nowrap
    .rs-col
      padding: 0 15px
      display: flex
      flex-wrap: wrap
      flex-direction: column
      justify-content: flex-end

    .comparison-property_value
      display: block
      color: $black
      padding-bottom: 20px
    .comparison-property_name
      display: block
      color: $dark-grey
      padding: 20px 0 10px 0
  strong
    font-weight: normal!important
  .comparison-property-product:nth-child(odd)
    background: #ededed

  .comparisonPageHeaderWrapper
    display: flex
    flex-grow: 1
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
    .btn-usual::before      
      background-image: $basket
      background-repeat: no-repeat
      content: ''
      display: inline-block
      width: 18px
      height: 18px
      vertical-align: middle
      position: relative
      left: -5px
      bottom: 2px

  .comparison-header
    display: flex
    flex-wrap: wrap
    background-color: $white
    align-items: center
    justify-content: space-between
    min-height: 70px
    margin-bottom: 10px

    .toggle-difference
      display: flex
      align-items: center
      padding-right: 20px
      font-size: 16px
      color: $black
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    .comparison-difference
      padding-right: 10px

  .react-tabs__tab-list
    border: none
    white-space: nowrap
    //display: flex
    padding-left: 10px 
    margin: 0
    .react-tabs__tab--selected
      border: none
      color: $black!important
    .category-name-tab
      max-width: 200px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
    span
      display: inline-block
      vertical-align: top
    .react-tabs__tab
      //display: flex
      align-items: center
      //height: 30px
      line-height: 30px
      margin: 0 10px
      padding: 0 30px 0 0
      border: none
      font-size: 16px
      color: $blue
      position: relative
    .react-tabs__tab:hover
      color: $blue-hover  
    .react-tabs__tab:active
      color: $blue-pressed


    .react-tabs__tab::after
      box-sizing: border-box
      content: ''
      display: block
      position: absolute
      right: -10px
      top: 0
      height: 30px
      width: 26px
      background: $slash no-repeat center
    .react-tabs__tab:last-child::after
      display: none
  .react-tabs__tab:focus:after
    left: auto

  .product-wrapper-tile
    margin: 0


.react-tabs__tab-panel
  .rs-row
    display: flex
.react-tabs__tab-panel::-webkit-scrollbar
   position: absolute
   top: 0

.comparisonPage
  .rs-grid-container-fluid
    width: fit-content
    margin-left: 0
    .comparison-tile-product .rs-col:first-child
      padding-left: 0
  

.category-list-wrapper
  display: flex
  flex-wrap: nowrap
.openDropdownCategory
  position: relative
  background: transparent $drop-down-list no-repeat right 5px top 7px
  color: $black
  padding: 0 22px 0 8px
  font-size: 14px
  font-weight: normal
  z-index: 5


.menu-category-list
  position: relative
  margin-right: 5px
  .dropdown
    background: $white
    position: absolute
    z-index: 10
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12)
    outline: none
    list-style: none
    font-size: 14px
    text-align: left
    background-color: $white
    border-radius: 6px
    padding: 6px 0
    margin-top: 10px 
    .react-tabs__tab--selected
      color: $blue

  .react-tabs__tab
    display: block
    color: $black
    top: 0
    left: 0
  .react-tabs__tab:hover
    color: $blue
    background-color: rgba(204, 233, 255, 0.5)
  .react-tabs__tab:active
    color: $blue-pressed
  .react-tabs__tab:disabled
    color: $blue-disabled

  .react-tabs__tab--selected
    border: none

.comparison-body-wrapper
  .empty-comparison
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-top: 10px
    h2
      margin-top: 0
      margin-bottom: 10px
      color: $dark-grey
    a
      font-size: 16px
      color: $blue
      display: block
      text-decoration: none
    a:hover
      color: $blue-hover
    a:active
      color: $blue-pressed
    a:disabled
      color: $blue-disabled
@media screen and (max-width: 440px)
  .comparison-body-wrapper .empty-comparison h2
    font-size: 18px


@media screen and (max-width: 860px)
  .comparisonPage
    .compare-body-products
      height: calc(100vh - 295px)
  .comparison-header
    flex-wrap: wrap
    padding: 15px 15px 0 15px
    .react-tabs__tab-list
      flex-wrap: nowrap
      width: 100%!important
      padding: 0
      .react-tabs__tab
        flex-wrap: nowrap
        white-space: nowrap
      .react-tabs__tab:first-child
        margin-left: 0
  .toggle-difference
    width: 100%
.sticky-row
  position: sticky
  top: 0

