@import "../../assets/sass/variables"
.help-page-wrapper
  main
    height: calc( 100vh - 80px )
    h1
      margin: 30px 0 20px 0
    ul
      color: $blue
      font-size: 16px
      margin-top: 30px
    ul:first-child
      margin-top: 0
    li
      margin-bottom: 5px
    a
      color: $blue
      text-decoration: none
    a:hover
      color: $blue-hover
    a:active
      color: $blue-pressed
    a:disabled
      opacity: 0.5

@media screen and (max-width: 1220px)
  .help-page-wrapper
    .page-container
      width: auto
      margin: 0 10px

@media screen and (max-width: 860px)
  .help-page-wrapper
    main
      height: calc( 100vh - 60px )