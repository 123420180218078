@import "../../../assets/sass/variables"
.profile-my-managers-item
    display: flex
    flex-wrap: nowrap
    padding: 20px 15px
    background-color: $white
    align-items: center
    margin-bottom: 5px
    color: $black
    font-size: 14px
    min-height: 55px
    justify-content: space-between

    .name
      width: 30%
    .phone
      width: 24%
      padding: 0 15px
    .email
      width: 26%
      padding: 0 15px
    .edit
      width: calc( 20% - 26px )
      padding: 0 15px
      .btn-edit
        margin-bottom: 0
    .delete
      width: 26px
      .btn-delete
        background-position: right

@media screen and (max-width: 1200px)
.profile-my-managers-item
  .phone
    width: 20%
    padding: 0 15px
  .edit
    width: calc( 24% - 26px )
    padding: 0 15px

@media screen and (max-width: 860px)
  .profile-my-managers-item
    flex-wrap: wrap
    position: relative
    padding: 20px
    .name
      width: 100%
      padding: 0 0 15px 0
    .phone
      width: 100%
      padding: 0 0 15px 0
    .email
      width: 100%
      padding: 0 0 15px 0
    .edit
      width: 100%
      padding: 0
    .delete
      width: 18px
      position: absolute
      right: 20px
      .rs-btn
        padding: 0
