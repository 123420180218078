@import "../../assets/sass/variables"
.exchange_rate-wrapper
  display: flex
.balance_wrapper
  height: 50px
  margin: 0 1px 15px 0
  background: $light-blue
  flex-grow: 1
  flex-basis: 50%
  border-radius: 5px 0 0 5px
  padding: 10px
  color: $dark-grey
  font-size: 10px
  span
    display: block
    font-size: 12px
    font-weight: 600
    overflow: hidden
    white-space: nowrap
  .red
    color: $red
  .green
    color: $green
.balance_wrapper.border-full
  border-radius: 5px
.rate_wrapper
  display: inline-block
  margin: 0 0 15px 0
  flex-grow: 1
  flex-basis: 50%
  height: 50px
  background: $light-blue
  border-radius: 0 5px 5px 0
  padding: 10px
  color: $dark-grey
  font-size: 10px
  span
    display: block
    color: $black
    font-size: 12px
    font-weight: 600
    overflow: hidden
    white-space: nowrap

header
  .balance_wrapper
    margin: 0 0 0 10px
    padding: 5px 10px
    height: 40px
    background: transparent

@media screen and (max-width: 860px)
  .balance_wrapper
    height: 40px
    padding: 5px
  .rate_wrapper
    height: 40px
    padding: 5px