.login-page
  overflow: hidden
  .login-header
    justify-content: space-between
.login-bg
  background: url(../../assets/img/login_bg.jpg) no-repeat 50% 50%
  background-size: cover
  height: 100vh
.login-form-wrapper
  height: 100vh
  .rs-form-control-wrapper
    left: 0
