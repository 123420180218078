@import "../../assets/sass/variables"
.status-1, .status-2, .status-3, .status-4, .status-0
  text-transform: uppercase
  font-size: 11px
  font-weight: 600
  display: inline-block
  padding: 5px 10px
  color: $black
  white-space: nowrap
.status-1
  background-color: #22A56E
.status-2
  background-color: #F3F302
.status-3
  background-color: #95E6FF
.status-4
  background-color: #E13838
.return-page-wrapper
  .status-0
    background-color: #22A56E
  .status-1
    background-color: #F3F302
  .status-2
    background-color: #95E6FF
  .status-3
    background-color: #E13838