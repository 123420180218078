@import "../../../assets/sass/variables"
    
.order-finished-wrapper
  h3
    color: $black
    font-size: 18px
    line-height: 22px
    padding: 35px 35px 15px
  .rs-grid-container
    width: 80%
    margin-top: 25px
    padding: 0
    background-color: $white
    .rs-col-lg-16
      width: 60%
      padding: 40px
      border-right: 2px solid $light-grey
    .order-finished-number
        font-size: 24px
        font-weight: 600
        color: $black
    .order-finished-info
      font-size: 14px
      font-weight: normal
      line-height: 20px
      color: $dark-grey
      margin-top: 10px
      padding-bottom: 20px
      border-bottom: 1px solid $light-grey
      a
        color: $blue
        text-decoration: none
      a:hover
        color: $blue-hover
      a:active
        color: $blue-pressed
      a:disabled
        color: $blue-disabled
    .order-finished-data_info
        font-size: 14px
        font-weight: normal
        padding-top: 20px
        
        h3
          font-size: 18px
          font-weight: 600
          color: $black
          padding: 0
    .order-finished-data_item
        display: flex
        flex-wrap: nowrap
        align-items: flex-start
        padding-top: 15px
    .order-finished-data_item-key
        display: inline-block
        color: $dark-grey
        min-width: 150px
        padding-right: 10px
    .order-finished-data_item-value
        color: $black
        display: inline-block


    .rs-col-lg-8   
        width: 40%
    .order-list-details-more
      max-height: 320px
      overflow: auto
      padding: 0 10px
      margin: 0 25px
    .order-finished-order
      padding: 0 35px
      .tabPanelWrapper
        height: auto
      .total-price
          color: $black
          font-size: 14px!important
          .description
            font-size: 14px
            font-weight: normal
          .price
              font-weight: 600
          .to-pay
            font-weight: 600
          .d-flex 
              padding-top: 10px

@media screen and (max-width: 960px) 
  .order-finished-wrapper
    .rs-grid-container
      width: calc( 100% - 20px) 

@media screen and (max-width: 940px)  
  .order-finished-wrapper
    .order-finished-number
      font-size: 20px!important
@media screen and (max-width: 860px)
  .order-finished-wrapper
    .rs-grid-container
      width: calc( 100% - 20px) 
      margin: 15px 10px 30px 10px
      
    .rs-row
      display: flex
      flex-direction: column
      margin: 0
    .rs-col-lg-16   
      width: 100%!important
      border-right: none!important
      padding: 20px!important
      border-bottom: 2px solid $light-grey-2
    .order-finished-number
      font-size: 20px!important
    h2
      padding-bottom: 15px!important
    .order-finished-data_item
      flex-direction: column
      .order-finished-data_item-key
        width: 100%
      .order-finished-data_item-value
        width: 100%
        padding-top: 5px
    .order-finished-data_info
      padding-top: 15px!important
      padding-right: 0!important
    
    .rs-col
      padding: 0

      // .rs-col-lg-8 нужно ли  для этого создать свой класс
    .rs-col-lg-8
      width: 100%!important

    .order-finished-order
      padding: 20px!important
    


