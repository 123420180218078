@import "../../assets/sass/variables"


@media screen and (min-width: 860px )
  .cart-page
    main
      background: $light-grey-2
    .basket-wrapper
      background: $white
      height: calc(100vh - 180px)
      display: block
      max-width: 100%
      width: 100%
      margin: 20px  auto 0
      .add-order
        display: none
      .react-tabs__tab-panel--selected
        display: flex
      .cart-wrapper
        padding-right: 10px
        flex: 1 1 70%
        width: 70%
        .tabPanelWrapper
          height: calc(100vh - 260px)
      .empty-cart
        margin: 0 auto
      .pr-10
        flex: 1 1 30%
        width: 30%
