@import "../../assets/sass/variables"
.breadcrumbs-wrapper
  font-size: 13px
  margin-bottom: 10px
.breadcrumbs-wrapper
  display: flex
  height: 16px
  margin-top: 5px
  align-items: center
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  .rs-breadcrumb-separator
    box-sizing: border-box
    position: relative
    background-image: $point
    background-repeat: no-repeat
    background-position: center
    padding-right: 4px
    text-indent: -999px
    //display: none!important
  .rs-breadcrumb-item
    span
      font-size: 13px
      font-weight: normal
    a
      color: $black

    a:hover
      color: $blue-hover
      text-decoration: none
    a:active
      color: $blue-pressed
      text-decoration: none
    a:disabled
      color: $blue-pressed

  .rs-breadcrumb-item-active
    position: relative
    color: $dark-grey
    top: 1px