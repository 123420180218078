@import "../../../assets/sass/variables"
.productInfo-usuallyBoughtTogether-wrapper
  display: block
  h2
    margin-bottom: 20px
  .productInfo-properties-list
    padding-bottom: 45px
    .rs-row
      margin-left: 0
    .rs-col:first-child
      padding-left: 0
    .product-info
      margin-right: 0
    .compare
      background-image: $compare-black
      margin-left: 10px

.productInfo-usuallyBoughtTogether-wrapper

  .slick-next, .slick-next:hover, .slick-next:focus, 
  .slick-prev, .slick-prev:hover, .slick-prev:focus
    display: block
    background-image: $arrowRight
    background-repeat: no-repeat
    background-position: center
    z-index: 10

  .slick-arrow
    width: 26px
    height: 26px
  .slick-initialized
    .slick-next, .slick-prev
      display: block!important
      top: -33px
      background-color: $light-grey-2
    .slick-prev
      left: calc( 100% - 94px )
    .slick-next
      right: 0


  .slick-prev, .slick-prev:hover, .slick-prev:focus
    background-size: 24px
    transform: rotate(-180deg)
    top: -46px!important
  .slick-next, .slick-next:hover, .slick-next:focus
    background-size: 24px

  .slick-next:hover, .slick-next:focus, .slick-prev:hover, .slick-prev:focus
    background-image: $arrowRight-blue
    background-size: 24px

  .slick-next:before, .slick-prev:before
    content: ''

  .slick-next:before
    box-sizing: border-box
    content: ''
    display: inline-block
    position: relative
    right: 34px
    height: 30px
    width: 26px
    background-image: $slash
    background-repeat: no-repeat
    background-position: center
