.login-email-wrapper
  .input-wrapper
    label
      text-align: left
  .rs-form-error-message-wrapper
    position: relative
    text-align: left
  .rs-form-error-message
    position: relative
    filter: none
    background: transparent
    border: none
    margin: 0
    padding: 0
    span
      display: block
      color: #cf4740
      font-size: 14px
      font-weight: normal
      text-align: left
      margin: 0
  .rs-form-error-message-arrow::after,
  .rs-form-error-message-arrow::before
    display: none
  .input-wrapper.has-error
    margin-bottom: 20px