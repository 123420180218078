@import "../../assets/sass/variables"

.view-wrapper
    box-sizing: border-box
    position: relative
    height: 18px
    width: 46px
    button
        padding: 0

.tableView
    height: 18px
    width: 22px
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.25 11.3794V6.75001C2.44891 6.75001 15.75 6.75001 15.75 6.75001C15.75 6.75001 15.75 2.44892 15.75 2.25001M2.25 11.3794V2.25L15.75 2.25001M2.25 11.3794V15.75H9H15.75C15.75 15.5511 15.75 2.25001 15.75 2.25001M15.75 11.5C15.75 11.5 2.44891 11.5 2.25 11.5' stroke='%230090D4' stroke-linecap='round'/%3E%3C/svg%3E%0A")
    background-position: top 0px left 0px
    background-repeat: no-repeat
    opacity: 0.6

.tileView
    height: 18px
    width: 23px
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.75 6.5H2C1.80109 6.5 1.25 6.5 1.25 6.5C1.25 6.5 1.25 5.94891 1.25 5.75V2C1.25 1.80109 1.25 1.25 1.25 1.25C1.25 1.25 1.80109 1.25 2 1.25H5.75C5.94891 1.25 6.5 1.25 6.5 1.25C6.5 1.25 6.5 1.80109 6.5 2V5.75C6.5 5.94891 6.5 6.5 6.5 6.5C6.5 6.5 5.94891 6.5 5.75 6.5ZM14.75 5.75V2C14.75 1.80109 14.75 1.25 14.75 1.25C14.75 1.25 14.1989 1.25 14 1.25H10.25C10.0511 1.25 9.5 1.25 9.5 1.25C9.5 1.25 9.5 1.80109 9.5 2V5.75C9.5 5.94891 9.5 6.5 9.5 6.5C9.5 6.5 10.0511 6.5 10.25 6.5H14C14.1989 6.5 14.75 6.5 14.75 6.5C14.75 6.5 14.75 5.94891 14.75 5.75ZM6.5 14V10.25C6.5 10.0511 6.5 9.5 6.5 9.5C6.5 9.5 5.94891 9.5 5.75 9.5H2C1.80109 9.5 1.25 9.5 1.25 9.5C1.25 9.5 1.25 10.0511 1.25 10.25V14C1.25 14.1989 1.25 14.75 1.25 14.75C1.25 14.75 1.80109 14.75 2 14.75H5.75C5.94891 14.75 6.5 14.75 6.5 14.75C6.5 14.75 6.5 14.1989 6.5 14ZM14.75 9.5H9.5V14.75H14.75V9.5Z' stroke='%230090D4' stroke-linecap='round'/%3E%3C/svg%3E ")
    background-position: top 1px left 5px
    background-repeat: no-repeat
    border-left: 1px solid $light-grey-3
    opacity: 0.6

.active
    opacity: 1


.products-filter-wrapper
  .btn
    font-weight: normal

.products-oderby-wrapper
  .btn::before
    display: none!important
  .rs-dropdown-placement-bottom-start
    display: flex
    align-items: center
    justify-content: center
    .rs-dropdown-toggle
      width: 100%
      height: 100%
      background-color: $blue 
      color: $white
      text-align: center
    .rs-dropdown-toggle:hover
      background: $blue-hover
      cursor: pointer
      color: $white
    .rs-dropdown-toggle:active
      background: $blue-pressed
      color: $white
    .rs-dropdown-toggle:disabled
      background: $blue-disabled
      color: $white
    .rs-dropdown-menu
      background-color: $white
      padding: 0
      z-index: 10
      .rs-dropdown-item
        background-color: $white
        padding: 10px


