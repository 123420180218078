@import "../../assets/sass/variables"
.order-page-wrapper
  main
    background: $light-grey-2
    display: block
    //min-height: calc( 100vh - 80px )
    .btn
      background: $blue
      border: none
      color: $white
      border-radius: 0
      // max-width: calc(50% - 5px)
      // width: 100%
      font-weight: 600
    .btn:hover
      background: $blue-hover
      cursor: pointer
    .btn:active
      background: $blue-pressed
    .btn:disabled
      background: $blue
      opacity: 0.5
    .btn-lg
      height: 45px
      line-height: 45px
      width: 100%
.send-order-wrapper
  background: $white
  margin: 0 auto 50px

  padding: 0 40px 40px
  max-width: 786px
  // width: 100%
  .btn
    max-width: 322px
    width: 100%
.order-finished-wrapper
  h3
    color: $black
    font-size: 18px
    padding: 35px 35px 15px
  .rs-grid-container
    width: 80%
    margin-top: 25px
    padding: 0
    background-color: $white
    .rs-col-lg-16
      width: 60%
      padding: 40px
      border-right: 2px solid $light-grey
    .order-finished-number
      font-size: 24px
      font-weight: 600
      color: $black
    .order-finished-info
      font-size: 14px
      font-weight: normal
      line-height: 20px
      color: $dark-grey
      margin-top: 10px
      padding-bottom: 20px
      border-bottom: 1px solid $light-grey
      a
        color: $blue
        text-decoration: none
      a:hover
        color: $blue-hover
      a:active
        color: $blue-pressed
      a:disabled
        color: $blue-disabled
    .order-finished-data_info
      font-size: 14px
      font-weight: normal
      padding-top: 20px

      h3
        font-size: 18px
        font-weight: 600
        line-height: 22px
        color: $black
    .order-finished-data_item
      display: flex
      flex-wrap: nowrap
      align-items: flex-start
      padding-top: 15px
    .order-finished-data_item-key
      display: inline-block
      color: $dark-grey
      min-width: 150px
      padding-right: 10px
    .order-finished-data_item-value
      color: $black
      display: inline-block


    .rs-col-lg-8
      width: 40%
    .order-list-details-more
      max-height: 370px
      overflow: auto
      padding: 0 35px
    .order-finished-order
      padding: 0 35px
      .tabPanelWrapper
        height: auto
      .total-price
        color: $black
        font-size: 14px!important
        .description
          font-size: 14px
          font-weight: normal
        .price
          font-weight: 600
        .to-pay
          font-weight: 600
        .d-flex
          padding-top: 10px

@media screen and (max-width: 960px)
  .order-finished-wrapper
    .rs-grid-container
      width: calc( 100% - 20px)

@media screen and (max-width: 940px)
  .order-finished-wrapper
    .order-finished-number
      font-size: 20px!important
@media screen and (max-width: 860px)
  .order-finished-wrapper
    .rs-grid-container
      width: calc( 100% - 20px)
      margin: 15px 10px 30px 10px

    .rs-row
      display: flex
      flex-direction: column
      margin: 0
    .rs-col-lg-16
      width: 100%!important
      border-right: none!important
      padding: 20px!important
      border-bottom: 2px solid $light-grey-2
    .order-finished-number
      font-size: 20px!important
    h2
      padding-bottom: 15px!important
    .order-finished-data_item
      flex-direction: column
      .order-finished-data_item-key
        width: 100%
      .order-finished-data_item-value
        width: 100%
        padding-top: 5px
    .order-finished-data_info
      padding-top: 15px!important
      padding-right: 0!important

    .rs-col
      padding: 0

    .rs-col-lg-8
      width: 100%!important

    .order-finished-order
      padding: 20px!important
@media screen and (max-width: 860px)
  .send-order-wrapper
    max-width: 766px
  .order-page-wrapper
    main
      //height: calc( 100vh - 60px )
      .order-details,
      .order .contact-data-wrapper,
      .shipping_wrapper
        padding: 0 !important

@media screen and (max-width: 788px)
  .send-order-wrapper
    width: 766
    margin: 0 10px 50px 10px




