@import "assets/sass/variables"
@font-face
  font-family: 'proxima_nova_rgregular'
  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot')
  src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'proxima_nova_rgbold'
  src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot')
  src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg')
  font-weight: normal
  font-style: normal


@font-face
  font-family: 'adelle_rgregular'
  src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot')
  src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix') format('embedded-opentype'),url('https://litmus.com/fonts/Emails/adelle_reg-webfont.woff') format('woff'),url('https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf') format('truetype'),url('https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular') format('svg')
  font-weight: normal
  font-style: normal

body
  font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif !important
img
  display: block
h1
  font-family: 'proxima_nova_rgbold', Helvetica, Arial, sans-serif
#root, .App, .App>div
  height: 100%
.App>div
  min-height: 100%
  display: flex
  flex-direction: column
  align-items: stretch
main
  padding: 60px 0 0
  h1
    color: #333333
    font-size: 24px
    font-weight: 600
    line-height: normal
.container
  margin: 0 auto
  max-width: 1164px
  width: 100%
.page-container
  margin: 0 auto
  width: 100%
  max-width: 1200px
button
  cursor: pointer
.btn
  background: $blue
  border: none
  color: $white
.btn:hover
  background: $blue-hover
  cursor: pointer
.btn:active
  background: $blue-pressed
.btn:disabled
  background: $blue
  opacity: 0.5

.btn-lg
  height: 45px
  line-height: 45px
  width: 100%
  font-weight: 600
.btn-sm
  height: 40px
  line-height: 40px
  width: 80px
.btn-border
  background: $white
  border: 2px solid $blue
  height: 40px
  line-height: 36px
  width: 80px
.btn-border:hover
  border-color: $blue-hover
.btn-border:active
  border-color: $blue-pressed
.btn-border:disabled
  color: $black
  opacity: 0.5
.btn-usual
  background: none
  border: none
  cursor: pointer
  color: $blue
.btn-usual:hover
  color: $blue-hover
.btn-usual:active
  color: $blue-pressed
.btn-usual:disabled
  opacity: 0.5


a
  color: $blue
  text-decoration: none
a:hover
  color: $blue-hover
a:active
  color: $blue-pressed
a:disabled
  opacity: 0.5
a.black
  color: $black
input
  border: none
header, main, footer
  flex-shrink: 0
.text-center
  text-align: center
.main-page
  main
    flex-grow: 1
    height: 100vh
    display: flex
    justify-content: space-between

@media screen and (max-width: 860px)
  .main-page
    main
      display: block

.d-flex
  display: flex
.space-between
  justify-content: space-between
.innerBlock
  background: $light-grey-2
  flex-grow: 1
  padding: 19px
  overflow-y: auto

footer
  border-top: 1px solid $light-grey
  height: 50px
  color: $dark-grey
  font-size: 14px
  line-height: 140%
  padding: 15px 20px
  z-index: 9
  background-color: $white
  width: 100%
  position: fixed
  bottom: 0
  left: 0

@media screen and (max-width: 860px)
  main
    display: block
  footer
    display: none

.checkbox
  background: transparent
  border: none
  display: flex
  align-items: center
  margin-bottom: 2px
  font-size: 12px
  line-height: 15px
  justify-content: start
  position: relative
  .value
    display: block
.checkbox::before
    box-sizing: border-box
    content: ''
    display: block
    height: 18px
    width: 18px
    min-width: 18px
    border: $blue 2px solid
    margin-right: 10px
.checkbox.active::before
  background: $blue $check no-repeat 50% 50%
input[type="checkbox"]:checked+.checkbox::before
    background: $blue $check no-repeat 50% 50%
input[type="checkbox"]+.checkbox.clear::before
  background: $blue no-repeat 50% 50%
input[type="checkbox"]+.checkbox.clear::after
  border: 1px solid $white
  content: ''
  width: 10px
  left: 4px
  position: absolute

/*CSS for table*/

/*CSS for  table show-grid*/

.innerBlock
  flex-basis: 960px

.show-grid
  margin-bottom: 15px 
  font-size: 12px
  font-style: normal
  font-weight: normal
  .rs-flex-box-grid
    flex-wrap: wrap

  .rs-flex-box-grid-item
    .rs-btn
      align-items: center
      font-size: 12px
      padding: 0
      color: $blue
      text-align: left
      background-color: $light-grey-2
    .rs-btn:hover, .rs-dropdown-item:hover
      color: $blue-hover
      background-color: $light-grey-2
    .rs-btn:active, .rs-dropdown-item:active
      color: $blue-pressed
      background-color: $light-grey-2

    .rs-dropdown-menu
      padding: 15px 15px 0 15px
      border-radius: 0

    .rs-dropdown-item
      align-items: center
      font-size: 12px
      padding: 0 0 15px 0
      color: $blue
      text-align: left

    .rs-dropdown-item:hover
      color: $blue-hover
      background-color: $white
    .rs-dropdown-item:active
      color: $blue-pressed
      background-color: $white
    
    .rs-dropdown-item-active
      color: $black
      font-weight: normal
      background-color: $white



  .rs-flex-box-grid-item-0
    display: flex
    align-items: center
    color: $black-grey
    margin: auto 0

    h1
      font-size: 24px
      font-weight: 600

      span
        color: $dark-grey
        font-size: 18px
        font-weight: normal
        margin-left: 10px
  


    .exel-order
      padding: 0 0 0 23px
      background-image: $exel
      background-repeat: no-repeat
      background-position: 0px center


               

 
    .rs-dropdown-placement-bottom-start
      svg
        display: none

  
table 
  table-layout: auto
  border-collapse: collapse
  border: none
  margin: 0
  width: 100%
  color: $black
  
  thead
    position: sticky
    top: 0
    z-index: 1
    background: $light-blue
    height: 43px
    text-align: left
    font-size: 13px


    th 
     padding-left: 8px
     font-weight: normal
   
    .vendorCode 
       width: 7%
       padding-left: 10px
 
    .url 
       width: 47px

    .lastPurchase 
       width: 9%
       color: $blue

    .rrc 
       width: 6.5%
       
    .price 
       width: 8%

    .left 
       width: 7%

    .toReserve 
       width: 75px

    .toCart 
       width: 75px
       
    .totalPrice 
       width: 7%

    .income 
       width: 6.5%

    .compareHeader
       width: 36px
       background: $light-blue

  tbody
    text-align: left
    font-style: normal
    font-size: 13px
    tr
      height: 54px
      border-bottom: 5px solid $light-grey-2
    tr:nth-child(odd)
     background-color: $white
     .wrapperCounter
       background: $white
     .wrapperCounter input
      background: $white


    tr:nth-child(even)
     background-color: $white-grey
     .wrapperCounter
       background: $white-grey
     .wrapperCounter input
      background: $white-grey
    
    td
      padding-left: 8px 
      i
       font-style: normal

    .name
      a
        color: $blue
        overflow: hidden
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2 
        white-space: pre-wrap
        text-decoration: none
      a:hover
        color: $blue-hover
      a:active
        color: $blue-pressed
      a:disabled
        color: $blue-disabled
    td.price
      span
        font-style: normal
        font-weight: 600
    .toReserve
      padding-left: 0
    .toCart
      padding-left: 0 
    td.totalPrice
     font-style: normal
     font-weight: 600
    td.income
     font-style: normal
     font-weight: 600 
    .compare
      margin: 0
.compare:disabled,
.compare.added
  background: $compare-grey no-repeat left 50%
@media screen and (min-width: 1000px)
  .img-zoom
    position: relative
    span
      position: absolute
      width: 38px
      height: 38px
      z-index: 2
    span:hover+img
      animation: scaleImage .5s forwards
      animation-iteration-count: 1
      border-radius: 1px
      border: 1px solid $light-grey
      position: relative
      transform-origin: top left
      z-index: 1
  .table-responsive
    tr:nth-last-child(-n+4)
      .img-zoom
        span:hover+img
          transform-origin: bottom left
    tr:nth-child(-n+4)
      .img-zoom
        span:hover+img
          transform-origin: top left
  @keyframes scaleImage
    0%
      transform: scale(1)
    100%
      transform: scale(9)

/*CSS for modal*/
.rs-modal-dialog
 .rs-modal-content
  border-radius: 0
  padding: 0
  background: $white
  .border-black
    margin-bottom: 20px!important
  .rs-modal-header
   padding: 0
   h4
    padding: 0 40px
    display: block
    margin-bottom: 8px
    font-style: normal
    font-weight: bold
    font-size: 24px
    line-height: 29px
    text-align: center
    color: $black
    white-space: pre-wrap
   h4#dialog-\:r1d\:-title
     margin-bottom: 20px

    
   span
    position: relative
    text-align: right
    display: block
    height: 34px
    width: 34px
    right: calc( -100% + 34px )
    top: 0
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='path-1-inside-1_195_19599' fill='white'%3E%3Cpath d='M6 18.0208L18.0208 6L18.7279 6.70711L6.70711 18.7279L6 18.0208Z'/%3E%3Cpath d='M18.0208 18.7279L6 6.70711L6.70711 6L18.7279 18.0208L18.0208 18.7279Z'/%3E%3C/mask%3E%3Cpath d='M6 18.0208L18.0208 6L18.7279 6.70711L6.70711 18.7279L6 18.0208Z' fill='%231D1D1B'/%3E%3Cpath d='M18.0208 18.7279L6 6.70711L6.70711 6L18.7279 18.0208L18.0208 18.7279Z' fill='%231D1D1B'/%3E%3Cpath d='M6 18.0208L5.29289 17.3137L4.58579 18.0208L5.29289 18.7279L6 18.0208ZM18.0208 6L18.7279 5.29289L18.0208 4.58579L17.3137 5.29289L18.0208 6ZM18.7279 6.70711L19.435 7.41421L20.1421 6.70711L19.435 6L18.7279 6.70711ZM6.70711 18.7279L6 19.435L6.70711 20.1421L7.41421 19.435L6.70711 18.7279ZM18.0208 18.7279L17.3137 19.435L18.0208 20.1421L18.7279 19.435L18.0208 18.7279ZM6 6.70711L5.29289 6L4.58579 6.70711L5.29289 7.41421L6 6.70711ZM6.70711 6L7.41421 5.29289L6.70711 4.58579L6 5.29289L6.70711 6ZM18.7279 18.0208L19.435 18.7279L20.1421 18.0208L19.435 17.3137L18.7279 18.0208ZM6.70711 18.7279L18.7279 6.70711L17.3137 5.29289L5.29289 17.3137L6.70711 18.7279ZM17.3137 6.70711L18.0208 7.41421L19.435 6L18.7279 5.29289L17.3137 6.70711ZM18.0208 6L6 18.0208L7.41421 19.435L19.435 7.41421L18.0208 6ZM7.41421 18.0208L6.70711 17.3137L5.29289 18.7279L6 19.435L7.41421 18.0208ZM18.7279 18.0208L6.70711 6L5.29289 7.41421L17.3137 19.435L18.7279 18.0208ZM6.70711 7.41421L7.41421 6.70711L6 5.29289L5.29289 6L6.70711 7.41421ZM6 6.70711L18.0208 18.7279L19.435 17.3137L7.41421 5.29289L6 6.70711ZM18.0208 17.3137L17.3137 18.0208L18.7279 19.435L19.435 18.7279L18.0208 17.3137Z' fill='%231D1D1B' mask='url(%23path-1-inside-1_195_19599)'/%3E%3C/svg%3E%0A")
    background-repeat: no-repeat
    background-position: top 10px right 10px
    svg
      display: none
   
  .rs-modal-body
    margin: 0
    padding: 0 40px 0 40px
    color: $black-grey
    font-style: normal
    font-weight: normal
    font-size: 12px
    flex-direction: column

    .inline-block
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      margin-bottom: 10px

    .description
      font-size: 14px
      color: $dark-grey
      text-align: center
      margin-bottom: 20px

    .rs-input
      //padding: 4px 0 4px 15px
      margin-bottom: 10px
      margin-top: 0
      line-height: 31px
      font-size: 12px
      font-weight: normal
      width: 100%
      background: $light-grey-2
      border: none
    .rs-input-number
      background: transparent
      height: 100%
    .form-subscribe-modal-wrapper
      width: 100%
      margin-bottom: 30px
      div
        position: relative
        margin-bottom: 10px
        input
          display: inline


    .rs-input:focus
      outline: none

    .border-b
      display: none
    
    .px-1
      width: 50%
      height: 18px
      margin-bottom: 10px

      input
        display: inline
  .rs-modal-footer
    width: 100%
    padding: 0 40px 40px

    button
      height: 45px
      width: 100%
      border-radius: 0
      background: $blue
      font-weight: 600

.table_settings-wrapper, .subscribe-wrapper
  .rs-modal-body
    padding-bottom: 0!important
.subscribe-wrapper
  .input-wrapper
    height: 50px
  .enter_phone-wrapper
    .rs-form-error-message
      top: 10px
/*CSS for basket*/

.react-tabs
 .react-tabs__tab-panel
  .title
    color: $black
    font-style: normal
    font-weight: 600
    font-size: 18px
    margin-top: 34px
    margin-bottom: 10px
    text-align: center

  p
    color: $black
    font-style: normal
    font-weight: normal
    font-size: 12px 
    text-align: center
    margin-bottom: 25px

  .btn
    font-weight: 600

.product-wrapper
  .img
    img
      width: 100%
  .btn
    font-size: 14px
    font-weight: 600



::-webkit-scrollbar
  height: 5px
  width: 4px
::-webkit-scrollbar-track
  background: $light-grey
::-webkit-scrollbar-thumb
  background-color: $blue


/*CSS for Pagination*/

.pagination-wrapper
  margin-top: 25px
  flex-direction: row-reverse
  .rs-pagination-btn
    font-size: 14px!important
    font-weight: 600
    height: 32px
    width: 32px
    color: $black
    border-radius: 50%
    border: 0!important
    background-color: $white
    margin: 0 10px
  .rs-pagination-btn:hover
    border: none
    color: $blue-hover
    background-color: $white
    box-shadow: none!important
  .rs-pagination-btn-active
    color: $white!important
    background-color: $blue!important
    box-shadow: none!important
  [aria-label="Next"],
  [aria-label="First"],
  [aria-label="Previous"],
  [aria-label="Last"],
  [aria-label="Next"]:hover,
  [aria-label="First"]:hover,
  [aria-label="Previous"]:hover,
  [aria-label="Last"]:hover
    background-color: $light-grey-2

.rs-panel-group-accordion
  .rs-panel-title
    height: 40px
    padding: 9px 15px
    background: $light-grey-2 //$drop-down-list no-repeat center right 20px
    display: block
    color: $black
    font-size: 12px
    line-height: 22px
    font-weight: normal
  .rs-panel-title::before
    background: $drop-down-list no-repeat center center
    content: ''
    display: block
    height: 100%
    width: 40px
    position: absolute
    top: 0
    right: 0
  .rs-panel-in
    .rs-panel-title
      background: $light-blue
    .rs-panel-title::before
      transform: rotate(-180deg)
  .rs-panel-header
    padding: 0
    svg
      display: none
@media screen and (max-width: 860px)
  .pagination-wrapper
    flex-direction: column
    .rs-pagination-btn
      height: 36px
      width: 36px
      margin: 0 5px

/*CSS for Login*/

.login-page
  .rs-flex-box-grid
    @media screen and (max-width: 860px)
      .login-form-wrapper
        width: 75%
      .login-bg
        width: 25%
    @media screen and (max-width: 550px)
      .login-form-wrapper
        width: 100%
      .login-bg
        display: none
      .rs-panel
        width: 350px
      .react-code-input
        padding: 20px 0


.login-form-block
  width: 100%!important
  height: calc( 100vh - 60px )!important
  display: flex!important
  justify-content: center
  align-items: center
  
.login-form
  text-align: center
  .rs-panel-body
    padding: 0
  .rs-panel
    position: relative
    width: 380px
  .rs-panel-header
    padding-bottom: 10px
  .error-msg
    color: $red
    text-align: left
    margin-bottom: 5px

  h3
    font-size: 24px
    font-weight: 600
    color: $black

  .login-subheader
    width: 380px
    padding: 0 calc( (380px - 274px)/2 )!important



  .rs-form-control-label
    font-size: 14px
    color: $black
    position: relative
    padding-bottom: 10px

  span
    display: inline-block
    font-size: 16px
    font-weight: 600
    color: $black
    padding-right: 9px
    margin-top: 10px

  .enter_phone-wrapper
    margin-bottom: 10px
    background-color: $light-grey-2
    padding: 11.5px 20px
    margin: 20px 0 10px 0 
    color: $dark-grey
    text-align: left

  .rs-input
    background-color: $light-grey-2
    border: none
    font-size: 16px
    color: $black
    padding: 4px 0 0 0
  .rs-input::placeholder
    color: $dark-grey

  .rs-input:focus
    outline: none
  .btn
    background: $blue
    border: none
    color: $white
    border-radius: 0
    margin-bottom: 10px
  .btn:hover
    background: $blue-hover
    cursor: pointer
  .btn:active
    background: $blue-pressed

  .react-code-input
    padding: 20px 15px
    width: 100%
    input
      width: 50px!important
      height: 60px!important
      margin: 0 5px!important
      padding: 0!important
      box-shadow: none!important
      border: none!important
      border-radius: 0!important
      background-color: $light-grey-2!important
      font-size: 16px!important
      color: $black!important
      text-align: center!important

    input:first-child
      margin-left: 0!important
    input:last-child
      margin-right: 0!important

  .login-codeFailed
    font-weight: normal
    padding-right: 9px

.info-catalog-wrapper
  .rs-flex-box-grid-item-2
    width: auto

/*CSS for home-page-mobile*/
@media screen and (max-width: 500px)
  .innerBlock
    h1
      padding-bottom: 10px
@media screen and (max-width: 860px)
  .innerBlock
    padding: 10px 10px 70px
    .breadcrumbs-wrapper
      margin: 0 0 15px 0
    
  .info-catalog-wrapper
    .rs-flex-box-grid-item
      width: calc( 50% - 2.5px )
      font-size: 14px      
      .btn::before
        content: ''
        display: inline-block
        width: 18px
        height: 18px
        vertical-align: middle
        position: relative
        left: -10px
        bottom: 2px
        background-image: $filter
        background-repeat: no-repeat
      .total-chosen-filters
        display: inline-block
        width: 19px
        height: 19px
        line-height: 19px
        font-size: 12px
        margin-left: 10px
        color: $black
        background-color: $white
        vertical-align: middle
        text-align: center
        border-radius: 50%
  .hideDesktopCart
    .total-products
      position: relative
      top: -48px
      right: -12px


    .rs-flex-box-grid-item-2
      margin: 5px 0 10px 0

  .rs-flex-box-grid
    padding-bottom: 10px

  // .rs-flex-box-grid-item-0   НУЖНЫ КЛАССЫ для стилизации кнопок фильтр...
  //   .rs-dropdown-toggle
  //     font-size: 14px
  //     padding: 0
  //     color: $white
  //     text-align: left
  //     background-color: $blue


.tabs-header-view
  display: flex
  flex-wrap: wrap
  background-color: $white
  align-items: center
  justify-content: space-between
  min-height: 70px
  margin-bottom: 5px

  .toggle-difference
    display: flex
    align-items: center
    padding-right: 20px
    font-size: 16px
    color: $black
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
  .comparison-difference
    padding-right: 10px

  .react-tabs__tab-list
    border: none
    white-space: nowrap
    //display: flex
    padding-left: 10px
    margin: 0
    .react-tabs__tab--selected
      border: none
      color: $black!important
    .category-name-tab
      max-width: 200px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
    span
      display: inline-block
      vertical-align: top
    .react-tabs__tab
      //display: flex
      align-items: center
      //height: 30px
      line-height: 30px
      margin: 0 10px
      padding: 0 30px 0 0
      border: none
      font-size: 16px
      color: $blue
      position: relative
    .react-tabs__tab:hover
      color: $blue-hover
    .react-tabs__tab:active
      color: $blue-pressed


    .react-tabs__tab::after
      box-sizing: border-box
      content: ''
      display: block
      position: absolute
      right: 0
      top: 0
      height: 30px
      width: 26px
      background: $slash no-repeat center
    .react-tabs__tab:last-child::after
      display: none
  .react-tabs__tab:focus:after
    left: auto

// DELETE BORDERAND OUTLINE
.rs-input-group, .rs-input-group:not(.rs-input-group-disabled):hover, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:focus-within
  border: none!important
.rs-input-group, .rs-input:focus, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:focus-within
  outline: none!important

//  For all inputs (my manager)
.input-wrapper
  display: block
  position: relative
  background-color: $light-grey-2
  margin-bottom: 10px
  height: 44px
  padding: 3px 15px 8px
  label
    display: block !important
    color: $dark-grey
    font-size: 10px
    margin-top: 0 !important
    position: relative
    z-index: 4
  .rs-form-group
    display: block
    width: 100%
    height: auto
    color: $black
    font-size: 12px
    font-weight: normal
    position: relative
    .rs-form-control-wrapper
      width: 100%
      height: 40px
      position: absolute
      top: 0

    .rs-input
      background: #F8F8F8!important
      border: none!important
      line-height: 15px!important
      margin-bottom: 0!important
      padding: 8px 0 3px !important
      font-size: 14px!important
      color: $black
      width: 100%
      height: 100%

  .rs-form-control-wrapper
    width: 100%
  .rs-picker-toggle-wrapper
    width: 100%
.input-wrapper.selectpicker
  .rs-picker-default
    .rs-picker-toggle.rs-btn
      background: transparent !important
      border: none
      padding-top: 17px
      padding-bottom: 13px
    .rs-picker-toggle.rs-btn:after
      background: $triangle-forward no-repeat top right
      content: ''
      display: block
      height: 12px
      width: 12px
      position: absolute
      right: 0
      top: 12px
      transform: rotate(90deg)
  .rs-picker-toggle-caret
    color: $blue
    top: 10px !important
    right: 0 !important
  .rs-picker-toggle-clean
    top: 0 !important
    right: 0 !important
  .rs-picker-toggle:focus-visible
    outline: none !important
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value
    color: $black !important
  .rs-picker-toggle-active,
  .rs-picker.rs-picker-focused
    -webkit-box-shadow: none
    box-shadow: none
  .rs-picker-default
    .rs-picker-toggle
      padding-left: 0
  .rs-stack-item
    svg
      display: none

.input-wrapper:last-child
  margin-bottom: 15px
.input-wrapper.has-error
  margin-bottom: 30px
.rs-toggle-checked .rs-toggle-presentation
  background-color: $blue !important
.rs-form-help-text
  position: absolute !important

.btn-wrapper
  width: 100%

.rs-picker-select-menu
  border-radius: 0!important
  .rs-picker-search-bar-input
    border-color: $blue!important
    border-radius: 0!important
    color: $black!important
  .rs-picker-search-bar-input:focus
    outline: none!important


.main-table
  .table-responsive
    .rs-table
      min-width: 940px
  .rs-table-row-header
    background-color: $light-blue
    .rs-table-cell-content
      background-color: $light-blue
      color: $blue
      font-size: 11px
      white-space: nowrap
    .rs-table-body-wheel-area
      .rs-table-cell-content
        font-size: 12px
        color: $black
      .rs-table-cell:nth-child(5), .rs-table-cell:nth-child(6)
        .rs-table-cell-content
          font-weight: 600
      .rs-table-cell-wrap
        .logo
          display: block
          float: left
          padding: 0 5px 0 0

      .rs-table-row:nth-child(odd)
        background-color: $white
      .rs-table-row:nth-child(even)
        background-color: $white-grey
        .rs-table-cell
          background-color: $white-grey

.show-items-wrapper.rs-flex-box-grid-item
  margin-top: 20px
  border: 2px solid $blue
  background-color: $white
  padding: 0 10px
  font-size: 14px
  width: 202px
  color: $black
  .rs-btn
    font-weight: 600
    color: $black
    background-color: $white
    svg
      display: none
  .rs-btn:after
    background: transparent $drop-down-list-8-12 no-repeat center 50%
    content: ''
    display: inline-block
    width: 24px
    height: 24px
    margin-right: 10px
    position: absolute
    right: -10px
    top: 12px
    margin-top: -6px
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1)
  .rs-dropdown-menu
    width: 202px
    // top: -5px!important
    left: -118px!important
    border-radius: 0
    .rs-dropdown-item
      align-items: center
      font-size: 12px
      color: $blue
      text-align: left

    .rs-dropdown-item:hover
      color: $blue-hover
      background-color: $white
    .rs-dropdown-item:active
      color: $blue-pressed
      background-color: $white
    
    .rs-dropdown-item-active
      color: $black
      font-weight: normal
      background-color: $white

.rs-checkbox
  .rs-checkbox-wrapper::before
    border-radius: 0
    height: 18px
    width: 18px
    border: $blue 2px solid
    visibility: visible
    opacity: 1
  .rs-checkbox-wrapper
    .rs-checkbox-inner::before
      border-radius: 0
      border: none
  .rs-checkbox-wrapper::before,
  .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-wrapper .rs-checkbox-inner::after
    transform: none !important
    -webkit-transform: none !important
    height: 18px
    width: 18px
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before
  background-color: $blue !important
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after
  background: $check no-repeat 50% 50%
  border: none !important
  content: ''
  display: block
  height: 18px !important
  width: 18px !important
  transform: none !important
  -webkit-transform: none !important
  margin: 0 !important

.rs-table-scrollbar
  opacity: 1 !important
.rs-table-scrollbar-handle
  background-color: $blue !important
.rs-table-scrollbar-vertical
  width: 4px !important
  .rs-table-scrollbar-handle
    width: 2px !important

.white-box
  background: $white
  margin-bottom: 20px
  padding: 40px
  .shipping_wrapper
    background: transparent
    padding: 0

.back-link
  padding-left: 20px
  a
    color: $blue
    text-decoration: none
  a:hover
    color: $blue-hover
    text-decoration: none
  a:active
    color: $blue-pressed
  a:disabled
    color: $blue-disabled
    text-decoration: none
  a:focus
    text-decoration: none
  a::before
    content: ''
    display: inline-block
    position: relative
    left: -14px
    top: 1px
    background-image: $triangle-forward
    width: 8px
    height: 12px
    background-repeat: no-repeat
    transform: rotate(180deg)

.shipping_wrapper
  .shipping-recipient
    gap: 0!important
    width: 100%
    margin-top: 15px
    .rs-stack-item
      width: 167px
      max-width: 50%
      height: 57px
      font-size: 14px
      font-weight: 600
      color: $black
      border-radius: 0
      border: 1px solid $light-grey

    .rs-stack-item:hover
      box-shadow: none
      border: 2px solid $blue-hover

    .rs-stack-item:active
      box-shadow: none
      border: 2px solid $blue-pressed

    .rs-stack-item:disabled
      box-shadow: none
      border: 2px solid $blue-disabled


    .rs-btn-active
      box-shadow: none
      border: 2px solid $blue

    .rs-btn-active:hover
      box-shadow: none

.choose-recipient_wrapper
  position: relative
  width: 100%
  margin-top: 15px
  font-size: 12px
  color: $dark-grey
  // background-color: $light-grey-2
  .choose-recipient
    width: 100%
    padding: 12px 15px
    text-align: left
  .active
    border-bottom: 2px solid $white
  .choose-recipient_list
    padding: 15px 0 0
    background-color: $light-grey-2
    max-width: calc(50% - 5px)
    .choose-recipient_item
      cursor: pointer
      border-bottom: 1px solid $light-grey-3
      background-color: $light-grey-2
      padding: 10px
      font-size: 12px
      .choose-recipient_name
        margin-bottom: 3px
    .choose-recipient_item:hover
      background: $light-blue
  .choose-recipient_item
    width: 100%
    padding: 15px 0 0
    color: $black
    text-align: left
    background-color: $white
    font-size: 14px
    .choose-recipient_name
      margin-bottom: 10px
    .choose-recipient_phone
      margin-bottom: 0
  .choose-recipient_item:last-child
    border: none
  .rs-form
    margin-top: 15px
    .rs-form-control-wrapper
      width: 100%
    .rs-form-group
      width: 100%
    .rs-input
      padding: 5px 15px
      font-size: 12px
      font-weight: normal
      color: $dark-grey
      background-color: $light-grey-2
  .enter_phone-wrapper
    position: absolute
    top: 12px
    .rs-input
      padding-left: 0!important

  .add-recipient
    margin-bottom: 10px
  .add-recipient-box
    background: $white
    padding: 0
    .input-wrapper
      margin-right: 0
      margin-bottom: 10px
    .has-error
      margin-bottom: 30px
    .rs-form
      margin-top: 10px
    .btn-lg
      margin-bottom: 0
@media screen and (max-width: 860px)
  .choose-recipient_wrapper
    .choose-recipient_list
      max-width: 100%

.shipping_wrapper
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  padding: 40px
  background-color: $white
  .rs-input
    background-color: $light-grey-2
    border: none
    outline: none
    color: $black
  .delivery-tab-services
    .rs-input
      padding: 17px 0 3px

  .shipping-header-wrapper
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

    .rs-btn
      margin-bottom: 15px
      padding: 0 0 0 28px
      background-image: $edit
      background-repeat: no-repeat
      background-position: 0 center

  .shipping-details-wrapper
    width: 100%

  .shipping-details_carrier
    margin-bottom: 15px
    span
      margin-right: 10px
  .shipping-details_address
    margin-bottom: 15px
  .shipping-details_payer
    margin-bottom: 15px
  .shipping-details_total_price
    margin-bottom: 40px

  .add-recipient
    padding: 0 0 0 10px
    color: $blue

  .add-recipient:hover
    color: $blue-hover

  .add-recipient:active
    color: $blue-pressed

  .add-recipient:disabled
    color: $blue-disabled


  .btn-add_comment
    width: 100%
    justify-content: left
    margin-top: 25px
  .btn-add_comment::before
    background-image: $plus-blue
    background-repeat: no-repeat
    content: ''
    display: inline-block
    width: 18px
    height: 18px
    padding: 0 14px
    vertical-align: middle

  .rs-input-group
    max-width: calc(50% - 5px)
    border: none
    height: 40px
    outline: none
    margin-bottom: 2px

    .rs-input
      font-size: 12px
      color: $black
    .rs-input::placeholder
      color: $dark-grey
    .rs-input-group-addon
      height: 100%
    .rs-input-group-addon:hover, .rs-input-group-addon:focus, .rs-input-group-addon:active
      background-color: $light-grey-2
  .rs-input-group-focus
    margin-bottom: 2px
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:focus-within
    outline: none
.recipient-name-wrapper
  font-size: 14px
.delivery-pay-info
  margin-bottom: 15px
  width: 100%
  .delivery-pay-info_payer
    margin-bottom: 15px
@media screen and (max-width: 860px)
  .shipping_wrapper
    padding: 0 20px!important
    .rs-input-group
      max-width: 100%


.rs-picker-menu
  background-color: $light-grey-2!important
  z-index: 100 !important
  .rs-picker-select-menu-item
    color: $black
  .rs-picker-select-menu-item:hover
    background-color: $light-grey-2
    color: $blue
  .rs-picker-select-menu-item:active
    color: $blue-pressed
  .rs-picker-select-menu-item-active
    background-color: $light-grey-2!important
    color: $black!important
.rs-modal-open
  .rs-picker-menu
    z-index: 1050 !important


.settlements-daterange
  .rs-picker-toggle-wrapper
    display: block
  .rs-picker-default .rs-picker-toggle.rs-btn
    background: $light-grey-2 $calendar no-repeat center right 10px !important
    border: none
    border-radius: 0
    padding-top: 20px
    padding-bottom: 5px
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value
    color: $black
  .rs-picker-toggle-active,
  .rs-picker.rs-picker-focused
    -webkit-box-shadow: none
    box-shadow: none
  .date_range-wrapper
    position: relative

  .download-date-range
    width: calc(100% - 230px)
    max-width: 210px
  .rs-stack-item
    svg
      display: none
  .rs-picker-has-value
    .rs-stack-item
      svg
        display: block
    .rs-picker-toggle.rs-btn
      background-image: none !important
      .rs-btn-close
        top: 12px !important
  .date-range-label
    color: $dark-grey
    font-size: 12px
    position: absolute
    top: 5px
    left: 10px
    z-index: 10
  .rs-picker-toggle-textbox
    background-color: transparent
    opacity: 0

  .settlements-rest
    width: 25%
    .label
      margin-bottom: 0
    .value
      color: $red
      font-size: 18px
      font-weight: 600
      span
        padding-right: 25px
    .btn-topUpBalance
      padding-top: 0
      padding-bottom: 0
.page-wrapper
  main
    display: block
    background: $light-grey-2
    overflow: auto
    min-height: 100vh
    .page-title
      margin: 0 auto
      padding-left: 10px
      padding-right: 10px
      width: 100%
      max-width: 1200px
@media screen and (max-width: 860px)
  .page-wrapper
    main
      height: calc(100vh - 120px)
      padding-bottom: 60px

.mt-15
  margin-top: 15px
.mb-15
  margin-bottom: 15px
.pl-5
  padding-left: 5px
.error-sm
  margin-bottom: 15px
  width: 100%
  .rs-message-container
    padding: 10px
    p
      color: $black
      margin: 0

.search-page
  main
    .table-responsive
      max-height: calc(100vh - 230px)
    .tile .show-grid
      height: calc(100vh - 240px)
    .page-title
      margin-top: 45px
    h2
      margin-top: 0
      margin-bottom: 10px
.video-wrapper
  margin: 4% 0 0
  position: relative
  padding-bottom: 56.25%
  iframe
    position: absolute
    height: 100%
    width: 100%
    top: 50%
    transform: translateY(-50%)

.page-404
  .page-title
    text-align: center
  h2
    color: $blue
    font-size: 50px
    line-height: 1.4