@import "../../../assets/sass/variables"
.shipping_wrapper-body
  width: 100%
  .react-tabs
    padding-bottom: 25px
  .react-tabs__tab-list
    border: none
    margin-bottom: 15px
    display: flex
    .react-tabs__tab
      font-size: 14px
      color: $black
      padding: 0
      margin-right: 20px
      display: flex
      align-items: center
    .react-tabs__tab::before
      box-sizing: border-box
      content: ''
      display: block
      height: 24px
      width: 24px
      min-width: 18px
      border: $blue 2px solid
      border-radius: 50%
      margin-right: 10px
    .react-tabs__tab--selected
      border: none
    .react-tabs__tab--selected::before
      border: $blue 9px solid
    .rs-input
      font-size: 12px
      color: $black
    .rs-input::placeholder
      color: $dark-grey
    .rs-input-group-addon
      height: 100%
    .rs-input-group-addon:hover, .rs-input-group-addon:focus, .rs-input-group-addon:active
      background-color: $light-grey-2
  .rs-input-group-focus
    margin-bottom: 2px
  .chosen-shipping-address
    display: flex
    flex-direction: column
    color: $black
    margin-top: 15px
    div
      padding-bottom: 10px
    span
      padding-bottom: 10px

  .choose-recipient_list
    position: relative
    z-index: 9
    background: $light-grey-2
    max-width: calc(50% - 5px)
    max-height: 160px
    overflow: auto
    display: flex
    flex-direction: column
    color: $black
    font-size: 12px

    .choose-address-shipping
      display: flex
      flex-wrap: wrap
      text-align: left
      border-bottom: 1px solid $light-grey-3
      padding: 10px
    .choose-address-shipping:hover
      background: $light-blue
    .choose-address-shipping:last-child
      border-bottom: none
      margin-bottom: 0
  form
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    .input-wrapper
      width: calc(50% - 5px)
    .delivery_date
      width: 100%
  .payment-info-wrapper
    margin-bottom: 15px
    form
      flex-direction: column
      .input-sum
        margin-bottom: 0
        .rs-input-number-btn-group-vertical
          display: none

  .rs-input-group-btn:hover, .rs-input-group-btn:focus, .rs-input-group-btn:active
    background-color: transparent!important
  .dropship-wrapper
    padding: 15px
    margin-bottom: 20px
    background: $light-grey-2
    border-radius: 5px


.search-shipping-wrapper
  position: relative
  margin-bottom: 15px

@media screen and (max-width: 860px)
  .search-shipping-wrapper
    .rs-input-group
      max-width: 100%
  .input-wrapper
    width: 100%!important
  .white-box
    padding: 20px
  .shipping_wrapper-body
    .choose-recipient_list
      max-width: 100%


