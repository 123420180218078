@import "../../../assets/sass/variables"
.contact-data_phone
  position: relative
.contact-data
  width: 100%
  .contact-data-header
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    height: 27px
    margin-bottom: 15px
    h3
      margin-bottom: 0

  .contact-data-body
    font-size: 14px
    color: $black
    display: flex
    align-items: center
    justify-content: left
    width: 100%
    .contact-data_phone
      margin: 0 20px
    .title
      display: block

  .rs-form-group
    //max-width: 320px
    margin-bottom: 10px!important
    .rs-form-control-wrapper
      width: 100%
    .rs-input
      width: 100%
      height: 40px
      background-color: $light-grey-2!important
      border: none
      outline: none
      color: $black
      font-size: 12px
  .enter_phone-wrapper
    font-size: 10px
    color: $dark-grey
    .rs-input
      position: absolute
      left: 0
      background-color: transparent!important


.profile-page
  .contact-data-header
    height: 22px
    justify-content: flex-start
    flex-wrap: wrap
    margin-bottom: 20px
    h3
      font-size: 18px
      line-height: 22px
      font-weight: 600
      color: $black
      display: inline-block
      margin-right: 28px
    .btn-save
      color: $blue
    .btn-save:hover
      color: $blue-hover
    .btn-save:active
      color: $blue-pressed
    .btn-save:disabled 
      opacity: $blue-disabled
    .btn-edit
      margin-bottom: 0

  .contact-data-body
    margin-bottom: 25px
    .title
      font-size: 13px
      color: $dark-grey
      padding-bottom: 5px
    .full-name
      padding-right: 40px

    .phone, .email
      padding: 0 40px
      border-right: 1px solid $light-grey
      
    .phone
      border-left: 1px solid $light-grey
    .contact-data_phone
      margin: 0
    .debt-limit
      padding-left: 40px

@media screen and (max-width: 860px)
  .profile-page
    .profile-personal-data
      margin-bottom: 30px!important
    .contact-data-header
      height: auto
      margin-bottom: 15px
    .contact-data-body
      flex-direction: column
      align-items: flex-start
      margin-bottom: 20px
      .phone, .email, .full-name, .debt-limit
        border: none
        padding: 0
      .phone, .email, .full-name
        margin-bottom: 15px 
@media screen and (max-width: 402px)
  .profile-page
    .contact-data
      h3
        margin-bottom: 10px


       

