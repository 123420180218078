@import "../../assets/sass/variables"
.search-address-wrapper
  .btn
    background: $blue
    font-weight: 600
    border: none
    color: $white
    border: none
    border-radius: 0
    width: 234px!important
    margin-left: 20px
  .btn:hover
    background: $blue-hover
    cursor: pointer
  .btn:active
    background: $blue-pressed
  .btn:disabled
    background: $blue
    opacity: 0.5
  .btn-lg
    height: 45px
    line-height: 45px
    width: 100%
  .btn::before
    content: ''
    display: inline-block
    width: 18px
    height: 18px
    vertical-align: middle
    position: relative
    left: -5px
    background-image: $plus-white
    background-repeat: no-repeat
  .rs-input
    background-color: $white
    color: $black
    padding-left: 43px!important
  .rs-input-group::before
    content: ''
    display: inline-block
    width: 18px
    height: 18px
    position: absolute
    left: 15px
    top: 11px
    background-image: $magnifying_glass
    background-repeat: no-repeat
    z-index: 5
  .rs-input-group-addon
    display: none
  .close
    background: $white $remove no-repeat 50% 50%
    padding-left: 20px