@import "../../../assets/sass/variables"
.rs-modal-sm
  width: 400px
.rs-modal-title
  margin-bottom: 20px!important
.rs-modal-body
  max-height: 1000px!important
  padding-bottom: 40px!important
  font-size: 12px

.order-status-state
  padding-bottom: 10px

.comparison-difference
  padding-right: 5px

.checkbox-wrapper
  margin-left: 30px

.rs-modal-content
  .btn
    margin-top: 10px
  .title_permission
    padding-bottom: 10px
.modal-manager
  .enter_phone-wrapper
    .rs-form-error-message
      top: 0px

// for Complaint
.modal-complain 
  h4
    margin-bottom: 10px!important
    font-weight: 600!important
  .rs-btn
    font-size: 14px
    font-weight: 600
    color: $white
    background: $blue
    border-radius: 0
  .rs-btn:hover
    background: $blue-hover
    cursor: pointer
  .rs-btn:active
    background: $blue-pressed
  .rs-btn:disabled
    background: $blue-disabled
  .rs-form-control
    width: 100%
    .rs-picker
      width: 100%
      border: none
      border-radius: 0
      background: $light-grey-2
      outline: none
      .rs-picker-toggle
        background: $light-grey-2!important
        height: 40px
        line-height: 26px
        font-size: 12px
        font-weight: normal
        span
          color: $dark-grey!important
        svg
          display: none
      .rs-picker-toggle::after
        content: ''
        display: inline-block
        position: relative
        right: -280px
        top: -22px
        background-image: $triangle-forward
        width: 8px
        height: 12px
        background-repeat: no-repeat
        transform: rotate(90deg)
  .rs-picker-toggle-active, .rs-picker.rs-picker-focused
    border: none
    outline: none
    box-shadow: none
    -webkit-box-shadow: none




    