@import "../../assets/sass/variables"
.settlements-wrapper
  main
    height: 100vh
  h2
    margin-top: 30px
  .page-container
    margin: 0 auto
    width: 100%
    max-width: 1200px
  .settlements-info-wrapper
    background: $white
    display: flex
    justify-content: space-between
    margin: 20px 0 30px
    padding: 40px
  .table-responsive
    thead
      tr:first-child
        background: $light-blue
    .table-responsive
      background: $white
      padding: 15px 10px
      tr,tr:nth-child(even)
        background: $light-grey-2
        border-color: $white

  .settlements-sum-bought,
  .settlements-sum
    border-left: 1px solid $border-grey
    border-right: 1px solid $border-grey
    text-align: center
    width: 20%
    .key-value-info
      display: inline-block
      text-align: left
      .label
        margin-bottom: 0
      .value
        font-size: 18px
        font-weight: 600
  .settlements-sum-bought
    border-right: none

  .settlements-daterange
    display: flex
    justify-content: space-between
    width: 35%
    padding-left: 30px
    position: relative

  .settlements-rest
    width: 25%
    .label
      margin-bottom: 0
    .value
      color: $green
      font-size: 18px
      font-weight: 600
      span
        padding-right: 25px
    .value.debt
      color: $red
    .btn-topUpBalance
      padding-top: 0
      padding-bottom: 0

  .rs-btn-icon
    background-color: transparent

@media screen and (max-width: 1200px)
  .settlements-wrapper
    .page-container
      padding: 0 10px
    .settlements-info-wrapper
      padding: 20px
    .settlements-rest
      width: 20%
    .settlements-daterange
      width: 40%
@media screen and (max-width: 900px)
  .settlements-wrapper
    .settlements-info-wrapper
      flex-wrap: wrap
    .settlements-daterange
      justify-content: flex-start
      margin-top: 20px
      padding: 0
      width: 100%
      .download-date-range
        margin-left: 20px
    .settlements-rest,
    .settlements-sum-bought,
    .settlements-sum
      width: 33.33%
    .settlements-sum
      border-right: none
@media screen and (max-width: 860px)
  .settlements-wrapper
    main
      height: auto
      h2
        margin-top: 15px
    .main-table
      .table-responsive
        .rs-table
          min-width: 100%
@media screen and (max-width: 640px)
  .settlements-wrapper
    .settlements-rest
      border-bottom: 1px solid $border-grey
      padding-bottom: 20px
      margin-bottom: 20px
      width: 100%
    .settlements-sum-bought,
    .settlements-sum
      border: none
      width: 50%
    .settlements-daterange
      flex-wrap: wrap
      .date_range-wrapper
        margin-bottom: 15px
        width: 100%
      .download-date-range
        margin-left: 0
        width: 100%
        max-width: 100%
