@import "../../assets/sass/variables"
.subscription-page-wrapper
  main
  .page-title
    padding: 30px 0 0 0
    margin: auto
    max-width: 1200px
  .table-subscriptions
    max-height: calc(100vh - 280px)
    tr
      background-color: $white
    thead
      tr
        background-color: $light-blue
  .subscriptions-category, .show-hide
    color: $blue
  .subscriptions-properties
    background-color: $light-grey-2
    padding: 5px 7px
    margin-right: 5px
  .inner-table
    background: $white
    padding: 20px
    table
      overflow: hidden
      background-color: $white
      thead
        tr
          background-color: $light-blue
          .income
            font-weight: normal
          .price
            span
              font-weight: normal
      tbody
        tr
          background-color: $light-grey-2
          border-color: $white
        .vendorCode
          color: $blue

            
  h2
    margin: 0
  .search-address-wrapper
    margin: 20px 0 15px 0
    .rs-input
      height: 45px
