@import "../../assets/sass/variables"
.tabPanelWrapper
  height: calc(100vh - 380px)
  margin-bottom: 15px
  padding-right: 10px
  overflow-y: auto
.tabPanelWrapperReserve
  height: calc(100vh - 385px)
  margin-bottom: 15px
  padding-right: 10px
  overflow-y: auto
.pr-10
  padding-right: 10px
.total-price
  color: #1D1D1B
.total-price .description
  font-size: 12px
.total-price .price
  font-weight: 600
.total-price .to-pay
  font-size: 14px
  font-weight: 600
.cart-page
  .tabPanelWrapperReserve
    height: calc(100vh - 260px)
    margin-right: 10px
    flex: 1 1 70%
    width: 70%
.basket-wrapper
  height: calc(100vh - 60px)
  padding: 20px 10px 20px 20px
  width: 240px
  flex-basis: 240px
  flex-grow: 0
  flex-shrink: 0
  .rs-panel-group
    border-radius: 0
  .rs-panel-body
    padding: 0
  .rs-panel
    margin-bottom: 15px
    border-radius: 0
  .rs-panel:last-child
    margin-bottom: 0
  .react-tabs__tab-list
    border: none
  .rs-panel-header
    padding: 0
  .react-tabs__tab
    border-bottom: 1px solid $light-grey
    font-size: 14px
    line-height: 22px
    width: 100%
    padding: 0 0 10px
    text-align: center
    .reserve,
    .basket
      height: 18px
      width: 18px
      display: inline-block
      padding-right: 7px
      box-sizing: content-box
    .basket
      background: $cart-url no-repeat left 50%
    .reserve
      background: $reserve no-repeat left 50%
  .react-tabs__tab-list
    display: flex
    margin-right: 10px
  .react-tabs__tab
    bottom: 0
    display: flex
    align-items: center
    justify-content: center
    white-space: nowrap
  .react-tabs__tab--selected,
  .react-tabs__tab--selected:focus
    border: none
    border-bottom: 1px solid $blue !important
    color: $blue
    .basket
      background: $cart-url_active no-repeat left 50%
    .reserve
      background: $reserve-blue no-repeat left 50%
  .react-tabs__tab--selected:focus:after
    display: none
  .product
    border-bottom: 1px solid $light-grey
    padding: 15px 0
    display: flex
    .title
      font-size: 12px
      line-height: 15px
  .product:last-child
    border-bottom: none
    padding-bottom: 0
  .remove
    content: ""
    background: $remove no-repeat 50% 50%
    height: 9px
    width: 9px
    display: inline-block
  .add-order
    margin: 15px 0 0
    padding: 0
    text-align: center
    width: 100%
  .checkout
    display: block
    margin: 15px 0 0
    height: 45px
    line-height: 45px
    text-align: center
  .checkout:hover,
  .checkout:active,
  .checkout:focus
    color: $white
    text-decoration: none
  .checkout.disable
    cursor: not-allowed
@media screen and (max-width: 860px)
  .tabPanelWrapper
    height: calc(100vh - 430px)
  .basket-wrapper
    height: calc(100vh - 120px)
    position: fixed
    z-index: 100
    .react-tabs
      display: none
      background: $white
      border: 10px solid $light-grey-2
      position: fixed
      top: 60px
      left: 0
      padding: 20px
      height: 100vh
      width: 100%
  .mobileView
    .react-tabs
      display: block
.delete-active_order
  text-align: center
  font-size: 12px
  width: 100%
  position: relative
  display: flex
  align-items: center
  justify-content: center
  margin-top: 15px
  span
    padding-left: 3px
.delete-active_order::before
  content: ''
  display: inline-block
  background: $basket no-repeat 0 0
  background-size: contain
  height: 17px
  width: 15px
  padding-right: 3px
