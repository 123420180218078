@import "../../assets/sass/variables"
.product:first-child
  border-top: 1px solid $light-grey
.product
  border-bottom: 1px solid $light-grey
  padding: 10px 0
  position: relative
  .title
    font-size: 12px
    line-height: 15px
  .code
    color: $dark-grey
    font-size: 11px
    line-height: 13px
  .product:last-child
    border-bottom: none
.product.missed
  background: #fff2f2
  padding: 10px
.wrapper-product
  .product-name
    color: $black
    font-style: normal
    font-weight: 400
    font-size: 13px
    margin: 0
    text-align: left
    padding-right: 20px
  .code
    margin: 5px 0 10px 0
  .count
    display: inline-block
    color: $dark-grey
    font-size: 12px
  .price
    color: $black
    position: relative
    padding-left: 15px
    display: inline-block
    text-align: left
    font-weight: 600
    font-size: 12px
  .remove
    margin-top: 4px
.remove
  content: ""
  background: $remove no-repeat 50% 50%
  height: 9px
  width: 9px
  display: block
  position: absolute
  right: 0
  top: 15px
  .add-order
    margin: 15px 0 0
    padding: 0
    text-align: center
    width: 100%
  .checkout
    margin: 15px 0 0
.missing-products
  display: inline-block