@import "../../assets/sass/variables"
.order
  padding: 50px 0 0
  font-size: 14px
  color: $black
  margin: 0 auto
  max-width: 786px
  width: 100%
  .checkbox
    height: 29px
    margin-bottom: 20px
    padding: 0
  .checkbox::before
    width: 24px
    height: 24px
    margin-right: 15px
  .white-box
    margin: 0
  .error-available-product
    li
      margin-bottom: 15px
    strong
      color: $black

  .order-product
    width: 67%
    max-height: 300px
    overflow-y: auto
    overflow-x: hidden
    padding-right: 10px
  h1
    margin-bottom: 20px
  h2
    position: relative
    margin-top: 0
    font-size: 24px
    line-height: 29px
    font-weight: 600
    color: $black
    top: 2px
  h3
    font-size: 22px
    line-height: 27px
    font-weight: 600
    color: $black
    display: inline-block
    margin-bottom: 15px
  .order-details
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    margin-bottom: 15px

    .rs-btn
      width: 70px
      justify-content: right

    .products-price-wrapper
      font-weight: 600

    .products-price_HRN
      margin-left: 18px

    .products-price_HRN::before
      box-sizing: border-box
      content: ''
      display: inline-block
      position: relative
      top: 3px
      right: 10px
      height: 16px
      width: 15px
      background-image: $slash
      background-repeat: no-repeat
      background-position: center
  .order-details.show
    padding-bottom: 0
  .order-price-details-more
    background-color: $white
    padding: 10px 0 15px
    font-size: 14px
    font-weight: 600
    span
      font-size: 16px
      margin-left: 45%

  .order-list-details-more
    background-color: $white
    padding: 0 0 15px

  .rs-btn
    padding: 0
    color: $blue

  .rs-btn:hover
    color: $blue-hover
    text-decoration: none
  .rs-btn:active
    color: $blue-pressed
    text-decoration: none
  .rs-btn:focus
    text-decoration: none

  .contact-data
    width: 100%
    margin: 25px 0 40px

  .contact-data-wrapper
    margin: 2px 0
    background-color: $white
    .contact-data-header
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      .btn-edit
        padding-left: 28px 

    .contact-data-body
      display: flex
      align-items: center
      justify-content: left
      width: 100%

      .contact-data_phone
        margin: 0 20px

  .choose-recipient_list
    .rs-input
      padding-left: 0
  
  .btn-add_comment~
    .rs-input
      margin-top: 10px



@media screen and (max-width: 860px)
  .order
    padding: 20px 10px 0 10px
    .order-details
      padding: 0 20px!important
    .contact-data-wrapper
      padding: 0 20px
@media screen and (max-width: 500px)
  .order
    .order-product
      width: 100%
    .order-price-details-more
      span
        margin-right: 35px
        float: right


