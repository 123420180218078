@import "../../../assets/sass/variables"
.slider-main_wrapper
  padding: 15px

.slider-wrapper
  width: calc(35% - 15px)
  max-width: 448px
  margin-right: 15px
  border: 1px solid $light-grey
  img
    width: 100%
    max-height: 400px
    max-width: max-content
    margin: 0 auto

.slick-list
  .slick-slide
    position: relative
    box-sizing: border-box

  .slick-slide > div
    background: $white
    display: flex
    flex-wrap: nowrap

.slick-track
  margin: 0
  display: flex
  align-items: center
  justify-content: center


.slider-thumb_wrapper
  .slick-prev, .slick-prev:hover, .slick-prev:focus
    transform: rotate(-180deg)
    top: calc( 50% - 8px )

  .slick-next:hover, .slick-next:focus, .slick-prev:hover, .slick-prev:focus
    background-image: $arrowRight-blue
    background-size: 16px
  .slick-track
    left: 0
  .slick-active
    opacity: 0.5
    border-right: solid 1px $light-grey
  .slick-current
    opacity: 1
    position: relative
  .slick-current::before
    content: ''
    background: $light-grey
    height: 100%
    width: 1px
    right: 0
  .img-wrapper
    padding: 0 10px

.slick-next:before, .slick-prev:before
  content: ''

.slider-main
  padding-bottom: 30px 
  .slick-prev, .slick-prev:hover, .slick-prev:focus,
  .slick-next, .slick-next:hover, .slick-next:focus
    background: transparent $arrow
    border-radius: 50%
    height: 30px
    width: 30px
  .slick-prev, .slick-prev:hover, .slick-prev:focus
    transform: rotate(90deg)
    top: calc( 50% - 15px )
  .slick-next, .slick-next:hover, .slick-next:focus
    transform: rotate(-90deg)
    top: calc( 50% - 15px )


.slick-next, .slick-next:hover, .slick-next:focus, 
.slick-prev, .slick-prev:hover, .slick-prev:focus
  display: block
  height: 16px
  width: 16px
  background-image: $arrowRight
  background-repeat: no-repeat
  background-position: center
  z-index: 10

.slider-main:after
  content: ''
  display: block
  position: absolute
  width: calc( 100% + 30px )
  height: 1px
  background-color: $light-grey
  left: -15px
  bottom: 15px


.slick-initialized
  .slick-next, .slick-prev
    display: none!important
  .slick-next
    right: 5px
  .slick-prev
    left: 5px
.slider-thumb_wrapper
  margin: 0 15px
  .slick-list
    max-height: 85px

  .slick-next, .slick-prev
    display: block!important
    background-color: transparent
  .slick-next
    right: -15px
  .slick-prev
    left: -15px


.slick-initialized:hover
  .slick-next, .slick-prev
    display: block!important

.slick-dots
  display: flex!important
  align-items: center!important
  justify-content: center
  li 
    height: 11px
    button
      height: 11px
    button:before
      font-size: 5px
      color: $blue
      opacity: 1
      height: 11px
      line-height: 0
      position: relative
      top: 1px
.slick-dots li.slick-active button:before
  opacity: 1
  font-size: 11px
  top: 2px
    

@media screen and (max-width: 860px)
  .slider-wrapper
    width: 100%
    max-width: 100%
    margin-right: 0
    margin-bottom: 25px








