@import "../../assets/sass/variables"
.balance-page-wrapper
  background: $light-grey-2
  h2
    margin: 30px 0 20px 0
  .page-title
    width: 100%
    max-width: 1200px
    margin: 30px auto
    .balance-page-info-wrapper
      position: relative
      display: flex
      flex-wrap: wrap
      width: 100%
    .balance-page-info
      width: 100%
      padding: 40px
      background-color: $white
      min-height: 230px
      h3
        font-size: 18px
        font-weight: 600
        line-height: 22px
        color: $black
        margin-bottom: 15px
      .copy-text
        color: $black
        cursor: pointer
        margin-bottom: 15px
      .copy-text::after
        content: ''
        display: inline-block
        width: 18px
        height: 18px
        vertical-align: middle
        position: relative
        left: 10px
        bottom: 2px
        background-image: $TTH
        background-repeat: no-repeat
    .balance-page-info:after
      box-sizing: border-box
      content: ''
      display: block
      position: absolute
      right: 0
      top: 73px
      height: 111px
      width: 155px
      background: $card no-repeat center
      vertical-align: middle

@media screen and (max-width: 1220px)
  .balance-page-wrapper
    .page-title
      margin: 15px 10px

@media screen and (max-width: 520px)
  .balance-page-wrapper
    .balance-page-info:after
      display: none!important