@import "../../assets/sass/variables"
.transactions-table
  height: calc(100vh - 370px)
  .table-mobile-view
    width: calc(100vw - 40px)
  .product-item
    background-color: $light-grey-2
    overflow: hidden
    .product-info
      background-color: $light-grey-2
      .rs-col-xs-5
        width: 20%
  .transaction-checkbox-wrapper
    position: relative
  input[type="checkbox"]
    height: 18px
    width: 18px
    z-index: 10
    position: absolute
@media screen and (max-width: 860px)
  .rs-table-cell-header
    .rs-table-cell-content
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis