.modal-shipping
  .react-tabs__tab-list
    display: flex
    border-bottom: none
    text-align: center
    margin-bottom: 0
    justify-content: center
  .react-tabs__tab
    font-size: inherit !important
    height: 54px !important
    padding: 10px 0
  .react-tabs__tab:last-child
    padding: 5px 0
  .pickup_tab
    display: block !important
    .value
      display: none
  .delivery-ukrpost-wrapper
    margin-top: 30px