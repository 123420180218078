@import "../../../assets/sass/variables"
.delivery-tab-services > .react-tabs__tab-list
  .react-tabs__tab::before, .react-tabs__tab--selected::before
    display: none !important
  .react-tabs__tab
    width: 25%
    height: 60px
    margin: 0 !important
    justify-content: center
    border: 1px solid $light-grey
    .pickup_tab
      display: flex
      align-items: center
      .value
        font-size: 16px
        font-weight: 600
        margin-left: 5px
    .ukr-post_tab,
    .dpd-post_tab,
    .raben-post_tab
      display: flex
      justify-content: center
      .value
        font-size: 16px
        font-weight: 600
        margin: 0 5px
        width: 76px
      img
        height: 24px
        margin-top: 10px
    .dpd-post_tab,
    .raben-post_tab
      img
        margin-top: 0
  .react-tabs__tab--selected
    border: 2px solid $blue !important
    border-radius: 0
  .react-tabs__tab:focus:after
    display: none
  .react-tabs__tab:hover
    box-shadow: none
    border: 2px solid $blue-hover
  .react-tabs__tab:active
    box-shadow: none
    border: 2px solid $blue-pressed
  .react-tabs__tab:disabled
    box-shadow: none
    border: 2px solid $blue-disabled
  .logo-delivery
    img
      height: auto
      max-width: 100%
      margin: 0 auto
  .pickup_tab
    img
      height: 24px
.modal-shipping
  .delivery-tab-services>
    .react-tabs__tab-list
      .react-tabs__tab
        width: 33.33% !important
        .ukr-post_tab
          .value
            font-size: 14px

@media screen and (max-width: 860px)
  .delivery-tab-services > .react-tabs__tab-list
    flex-wrap: wrap
    .react-tabs__tab::before, .react-tabs__tab--selected::before
      display: none
    .react-tabs__tab
      width: 50%


.delivery-services-item
  .react-tabs
    padding-bottom: 25px
  .react-tabs__tab-list
    border: none
    margin-bottom: 15px
    display: flex

    .react-tabs__tab
      font-size: 14px
      color: $black
      padding: 0
      margin-right: 20px
      display: flex
      align-items: center
    .react-tabs__tab::before
      box-sizing: border-box
      content: ''
      display: block
      height: 24px
      width: 24px
      min-width: 18px
      border: $blue 2px solid
      border-radius: 50%
      margin-right: 10px
    .react-tabs__tab--selected
      border: none
    .react-tabs__tab--selected::before
      border: $blue 9px solid
    .rs-input
      font-size: 12px
      color: $black
    .rs-input::placeholder
      color: $dark-grey
    .rs-input-group-addon
      height: 100%
    .rs-input-group-addon:hover, .rs-input-group-addon:focus, .rs-input-group-addon:active
      background-color: $light-grey-2
  .rs-input-group-focus
    margin-bottom: 2px
  .chosen-shipping-address
    display: flex
    flex-direction: column
    font-size: 12px
    color: $black
    margin-top: 15px
    div
      padding-bottom: 10px
    span
      padding-bottom: 10px
.delivery-services-item>
  div
    padding: 10px 0
.modal-shipping
  .delivery-services-item
    .react-tabs
      padding-bottom: 0
    .react-tabs__tab-list
      .react-tabs__tab
        margin-right: 9px