@import "../../assets/sass/variables"
.autocomplete-block
  position: relative
  width: 100%
.autocomplete-wrapper
  width: 100%
  position: relative
  z-index: 100
.autocompleteData
  background: $white
  position: absolute
  top: 40px
  z-index: 10
  width: 100%
  display: flex
  flex-wrap: wrap
  max-height: 475px
  align-items: flex-start
  min-height: 72px
  h3
    font-size: 14px
    color: $dark-grey
    font-weight: normal
.empty
  justify-content: center
  align-items: center
.closeAutocomlete
  content: ''
  background: $remove no-repeat 50% 50%
  height: 100%
  width: 9px
  display: block
  margin-left: 9px
  position: absolute
  top: 0
  right: 0
  z-index: 101
@media screen and (max-width: 860px)
  .empty
    align-items: flex-start
  .autocomplete-wrapper
    background: $white
    position: fixed
    left: 0
    top: 0
    padding: 10px
    height: calc(100% - 60px)
    width: 100%
    display: none
  .autocompleteData
    height: calc(100% - 70px)
    overflow-y: auto
    top: 60px
    width: calc(100% - 20px)
  .closeAutocomlete
    position: fixed
    top: 10px
    right: 20px
    height: 40px

.autocompleteDataCategory
  position: relative
  top: 0
  width: 30%
  margin-top: 25px
  padding: 0 25px 0 25px
  h3
    padding: 0 0 15px 0
    color: $black
    font-size: 18px
    font-weight: 600
    line-height: 22px
  li
    margin-bottom: 15px!important
  a
    color: $blue
    font-size: 14px
    font-weight: normal
    text-align: left
    padding: 0

  a:hover
    color: $blue-hover
    text-decoration: none
  a:active
    color: $blue-pressed
    text-decoration: none
  a:disabled
    color: $blue-pressed
  a:focus
    text-decoration: none
a:focus
 text-decoration: none

.autocompleteDataProducts
  width: calc( 70% - 5px )
  max-height: 370px
  height: calc( 100vh - 165px )
  margin-top: 25px
  margin-bottom: 20px
  padding: 0 18px 0px 20px
  overflow-y: auto
  border-left: 1px solid $light-grey
  li
    border-bottom: 1px solid $light-grey
    padding: 15px 0 15px 55px 
  li:first-child
    padding-top: 0
  li:last-child
    border-bottom: none
  .productName
    padding: 0
    position: relative
    color: $black
    font-size: 12px
    img
      position: absolute
      display: inline-block
      width: 45px
      height: auto
      left: -55px
      top: 2px
  
    .title
      display: inline-block
      line-height: 15px
      margin-bottom: 4px 
      text-align: left
    .price
      font-weight: 600
      text-align: left

  .productName:hover
    color: $blue-hover
    text-decoration: none

  .productName:active
    color: $blue-pressed
    text-decoration: none  
  
  .productName:disabled
    color: $blue-disabled
    text-decoration: none  

.autocompleteDataResults
  width: calc( 100% - 50px ) 
  margin: 0 25px
  height: 60px
  border-top: 1px solid $light-grey
  text-align: center
  span
    margin-right: 15px
    line-height: 60px
    color: $dark-grey
  a
    color: $blue
    font-size: 14px
    font-weight: normal

  a:hover
    color: $blue-hover
    text-decoration: none
  a:active
    color: $blue-pressed
    text-decoration: none

@media screen and ( max-width: 860px )
  .autocomplete-wrapper
    background: $white
  .autocompleteData
    max-height: none
  
  .autocompleteDataCategory
    position: sticky
    top: 0
    z-index: 1
    width: 100%
    background: $white
    margin-top: 5px
    padding: 0 0 5px 0px
    border-bottom: 1px solid $light-grey
    h3
      padding: 0 0 10px 0
    ul
      display: flex
      flex-wrap: wrap
      margin-bottom: 0
      li
        margin-right: 15px
        margin-bottom: 10px!important

  .autocompleteDataProducts
    width: 100%
    max-height: calc( 100vh - 250px )
    height: auto
    margin-top: 15px
    margin-bottom: 0
    padding: 0
    border-left: none
 
  .autocompleteDataResults
    position: sticky
    bottom: 0
    z-index: 1
    background: $white
    width: 100% 
    margin: 0
    text-align: left
    padding-top: 15px 
    span
      line-height: normal

