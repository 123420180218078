@import "../../assets/sass/variables"
.block-filters-overflow
  height: calc(100vh - 140px)
  overflow-y: auto
  width: calc(100% + 10px)
  padding-right: 10px
.filters-wrapper.rs-sidenav-collapse-in
  flex-basis: 220px
  flex-grow: 0
  flex-shrink: 0
.filters-wrapper
  background: $white
  padding: 20px
  box-sizing: content-box
  .title-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    .title
      font-weight: 600
      font-size: 18px
      line-height: 24px
      color: $black
  .count
    position: relative
    font-size: 10px
    color: $dark-grey
    padding-left: 5px
    bottom: 0
  .block-filters
    border-bottom: 1px solid $light-grey
    margin-bottom: 15px
    padding: 0 0 15px
    .rs-panel-body
      padding: 0
      .rs-grid-container-fluid
        padding: 0
    .title
      background: transparent
      font-weight: 600
      font-size: 16px
      line-height: 19px
      // margin-bottom: 10px
      position: relative
      padding-left: 23px
    .title::before
      background: $light-grey-2 $drop-down-list no-repeat center 50%
      content: ''
      display: inline-block
      width: 12px
      height: 12px
      //border-style: solid
      //border-width: 0 6px 9px 6px
      //border-color: transparent transparent $blue transparent
      margin-right: 10px
      position: absolute
      left: 3px
      top: 18px
      margin-top: -6px
      transition: transform 0.25s cubic-bezier(0, 0, 0, 1)
      transform: rotate(180deg)
  .block-filters:last-child
    border: none
    margin-bottom: 0
    padding-bottom: 0
  .rs-panel-in
    .title::before
      transform: rotate(0)
  .checkbox
    padding-left: 0    
    align-items: flex-start
    .value
      color: $black
      text-align: left
      position: relative
      top: 3px
  .checkbox::before
    box-sizing: border-box
    content: ''
    display: block
    height: 18px
    width: 18px
    border: $blue 2px solid
    margin-right: 10px
  .checkbox.active::before
    background: $blue $check no-repeat 50% 50%
  .checkbox[disabled]::before
    border-color: $dark-grey
  .value:first-letter
    text-transform: uppercase
  .btn
    width: 100%
  .rs-panel
    border-radius: 0
  .rs-panel-header
    padding: 0
.close-filters
  height: 24px
  width: 24px
  display: flex
  align-items: center
.close-filters::before
  content: ''
  display: inline-block
  width: 16px
  border-bottom: 1px solid $black
.clear-all
  margin-top: 7px
.remove-filter
  background: $light-grey-2
  margin: 0 5px 5px 0
  padding: 5px 7px
.remove-filter::after
  content: ''
  background: $remove no-repeat 50% 50%
  height: 9px
  width: 9px
  display: inline-block
  margin-left: 9px
.szh-accordion__item-content
  transition: height 0.25s cubic-bezier(0, 0, 0, 1)
.szh-accordion
  .title
    font-weight: 600
    font-size: 16px
    line-height: 19px
    margin-bottom: 10px
    position: relative
    padding-left: 23px
  .title::before
    content: ''
    display: inline-block
    width: 0
    height: 0
    border-style: solid
    border-width: 0 6px 9px 6px
    border-color: transparent transparent $blue transparent
    margin-right: 10px
    position: absolute
    left: 0
    top: 6px
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1)
.szh-accordion__item--status-exited
  .title::before
    transform: rotate(180deg)




.rs-slider
  margin: 0 auto
  width: calc(100% - 20px)
.rs-slider-handle:hover::before,
.rs-slider-handle:focus::before
  box-shadow: rgba(52, 152, 255, 0.25) 0 0 0 3px
.rs-slider-bar
  height: 2px
  margin: 10px 0 15px
.rs-slider-progress-bar
  background: $blue
  border-radius: 1px
  height: 2px
.rs-slider-handle::before
  border: solid 4px $blue
  top: -4px
.rs-input
  background: $light-grey
  border-radius: 0

.rs-sidenav-toggle
  border: none
  padding-top: 10px

.rs-sidenav-toggle-button
  max-height: 24px
  max-width: 24px
  padding: 0
  background-image: $minus
  background-size: 24px
  background-color: $white!important
  background-repeat: no-repeat
  background-position: center
  svg
    display: none
.rs-sidenav-toggle-button:hover
    background-image: $minus-blue

.rs-ripple
  display: none


[aria-label="Expand"]
  background-image: $filter-black
  background-size: 24px

[aria-label="Expand"]:hover
  background-image: $filter-blue


.rs-sidenav-collapse-out .title-wrapper
  justify-content: center

.rs-panel-title
  height: auto!important

@media screen and (max-width: 860px)
  .filters-wrapper
    display: none
  .filters-wrapper.mobile
    display: block
    position: fixed
    height: 100vh
    width: calc(100vw - 85px)
    top: 0
    left: 0
    z-index: 100
    padding: 0
    .rs-sidenav-body
      z-index: 8
      position: relative
      background: white
      padding: 20px
  .block-filters-overflow
    height: calc(100vh - 40px)
    position: relative
    z-index: 10
  .overflow.filters
    height: 100vh
    z-index: 6
  .close-filters-mobile
    background-image: $close-filters-mobile
    border-radius: 50%
    height: 44px
    width: 44px
    position: fixed
    top: 50%
    margin-top: -22px
    right: 30px
    z-index: 10