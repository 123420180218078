.uploader-fake-wrapper
  position: relative
  height: 130px
  .uploader
    position: absolute
    height: 80px
    width: 100%
    z-index: 10
    .rs-form-control-wrapper
      display: block
      input[type='file'],
      .rs-btn
        height: 80px
        width: 100%
        opacity: 0