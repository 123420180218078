@import "../../assets/sass/variables"
.profile-page
  main
    h1
      margin: 30px 0 20px 0
    h3
      font-size: 18px
      line-height: 22px
      font-weight: 600
      color: #1D1D1B
      display: inline-block
      margin-right: 28px
    .profile-personal-data
      padding: 40px
      background-color: $white
      margin-bottom: 50px
    .order-status-state
      display: flex
      font-size: 14px
      color: $black
      align-items: center
      margin-bottom: 20px
      margin-top: 15px
      padding: 0
      .comparison-difference
        padding-right: 10px
    .delivery-tab-services
      padding-bottom: 20px
      .tabs-header-view
        margin-bottom: 20px
        overflow-x: auto
    .search-address-wrapper
      .btn
        background: $blue
        font-weight: 600
        border: none
        color: $white
        border-radius: 0
        width: 217px!important
        margin-left: 20px       
      .btn:hover
        background: $blue-hover
        cursor: pointer
      .btn:active
        background: $blue-pressed
      .btn:disabled
        background: $blue
        opacity: 0.5
      .btn-lg
        height: 45px
        line-height: 45px
        width: 100%
      .btn::before
        content: ''
        display: inline-block
        width: 18px
        height: 18px
        vertical-align: middle
        position: relative
        left: -5px
        background-image: $plus-white
        background-repeat: no-repeat
      .rs-input
        background-color: $white
        color: $black
        padding-left: 43px!important
      .rs-input-group::before
        content: ''
        display: inline-block
        width: 18px
        height: 18px
        position: absolute
        left: 15px
        top: 11px
        background-image: $magnifying_glass
        background-repeat: no-repeat
        z-index: 10
      .rs-input-group-addon
        display: none
    .shipping-address-list
      padding: 15px 0 0 0

    .add
      width: 220px
      margin-bottom: 20px
      font-weight: 600
    .add::before
        content: ''
        display: inline-block
        width: 18px
        height: 18px
        vertical-align: middle
        position: relative
        left: -5px
        background-image: $plus-white
        background-repeat: no-repeat
    .pagination-wrapper
      margin-top: 20px


@media screen and (max-width: 1200px)
  .profile-page
    .container
      padding: 0 10px

@media screen and (max-width: 860px)
  .profile-page
    main
      .profile-personal-data
        padding: 20px!important
      .delivery-tab-services
        padding-bottom: 30px!important
      .search-address-wrapper
        .rs-input-group-inside
          flex-wrap: wrap
        .btn
          width: 100%!important
          margin-left: 0!important
          margin-top: 10px
      .add
        width: 100%
    

@media screen and (max-width: 500px)
  .profile-page
    .profile-personal-data
      h3
        width: 100%
        padding-bottom: 10px