@import "../../assets/sass/variables"
.profile-contact_person-item
    display: flex
    flex-wrap: nowrap
    padding: 20px 15px
    background-color: $white
    align-items: center
    margin-bottom: 5px
    color: $black
    font-size: 14px
    min-height: 55px
    justify-content: space-between

    .name
      min-width: 45%
    .phone
      width: 35%
      padding: 0 15px

    .edit
      width: calc( 20% - 30px )
      padding: 0 15px
      .btn-edit
        margin-bottom: 0
    .delete
      width: 26px
      .btn-delete
        background-position: right


@media screen and (max-width: 860px)
  .profile-contact_person-item
    position: relative
    flex-wrap: wrap
    padding: 20px
    .name
      width: 100%
      padding: 0 0 15px 0
    .phone
      width: 100%
      padding: 0 0 15px 0
    .edit
      width: 100%
      padding: 0
    .delete
      width: 18px
      position: absolute
      right: 20px
      .rs-btn
        padding: 0
