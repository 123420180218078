@import "../../assets/sass/variables"

.wrapperCounter
  background: $light-grey-2
  border: none
  box-sizing: border-box
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  width: 72px
  height: 38px
  padding: 9px 5px

.product-item
  .wrapperCounter
    background: $light-blue-2
    input
      background: $light-blue-2!important
  

.wrapperCounter input
 background: $light-grey-2
 margin: 0
 padding: 0
 width: 28px
 height: 20px
 color: black
 text-align: center
 font-size: 12px
 font-weight: 600
 line-height: 0
  
.wrapperCounter button
 margin: 0
 padding: 0
 width: 18px
 height: 18px
 text-align: center
 font-size: 12px
 line-height: 0
 color: black
  
  
.wrapperCounter button:first-child
  background-image: $minus

.wrapperCounter button:first-child:disabled
  opacity: 0.5

.wrapperCounter button:first-child:hover
  background-image: $minus-blue

.wrapperCounter button:last-child
  background-image: $plus

.wrapperCounter button:last-child:hover
  background-image: $plus-blue

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button
 -webkit-appearance: none

input[type='number'], input[type="number"]:hover, input[type="number"]:focus
 appearance: none
 -moz-appearance: textfield
