@import "../../assets/sass/variables"
.my-order-single-wrapper
  background-color: $light-grey-2
  main
    height: auto
    max-width: 1400px
    margin: 30px auto 50px auto



  .my-order-single-header
    display: flex
    flex-wrap: wrap
    margin: 10px 0 15px 0
    align-items: center
    .my-order-single-id
      margin-right: 25px
      color: $black
      font-size: 24px
    .my-order-single-created
      height: 23px
      color: $black
      text-transform: uppercase
      font-size: 11px
      font-weight: 600
      padding: 5px 10px
    .my-order-single-download
      width: 132px
      margin-left: auto
      margin-right: 25px
    .my-order-single-repeat
      margin-right: 25px
      .btn-repeat
        padding: 0 0 0 23px
        color: $blue
        background: url(../../assets/img/repeat.svg)  no-repeat 0 center
      .btn-repeat:hover
        color: $blue-hover
      .btn-repeat:active
        color: $blue-pressed
      .btn-repeat:disabled
        color: $blue-disabled
      
    .my-order-single-decline
      .btn-decline
        padding: 0 0 0 23px
        color: $blue
        background: $cancel  no-repeat 0 center
      .btn-decline:hover
        color: $blue-hover
      .btn-decline:active
        color: $blue-pressed
      .btn-decline:disabled
        color: $blue-disabled


    
  .table-responsive
    height: auto
  .info-order-wrapper
    background-color: $white
    padding: 40px
    margin-bottom: 50px
  .info-order-detail-wrapper
    display: flex
    flex-wrap: wrap
    .key-value-info
      margin-bottom: 20px
    .invoiceShipping
      .value::after
        content: ''
        display: inline-block
        width: 18px
        height: 18px
        vertical-align: middle
        position: relative
        left: 5px
        bottom: 2px
        background-image: $TTH
        background-repeat: no-repeat

  .col-1,
  .col-2
    width: 50%
  .col-1
    padding-right: 50px
  .col-2
    .delivery-volume
      display: flex
      align-items: center
      margin-bottom: 20px
      .delivery-volume-item
        padding: 0 15px
        border-right: 1px solid $light-grey
      .delivery-volume-item:first-child
        padding-left: 0
      .delivery-volume-item:last-child
        border-right: none
        padding-right: 0
      .key-value-info
        margin: 0
  .btn-editOrder
    color: $blue
    padding: 0 0 0 28px
    margin-top: 5px
    background: $edit no-repeat 0 center
  .btn-editOrder:hover
    color: $blue-hover
  .btn-editOrder:active
    color: $blue-pressed
  .btn-editOrder:disabled
    color: $blue-disabled
    

  .rs-flex-box-grid-top
    margin-bottom: 20px
    width: calc( 100% - 330px)
    h2
      margin: 0
    .generic-btn
      margin: 0
      padding: 0 5px 0 28px
      color: $blue

  .order-details-wrapper
    display: flex
    flex-wrap: wrap
    margin-bottom: 30px
    .table-orders
      width: calc( 100% - 330px)
      margin-right: 30px

    .price-wrapper
      width: 300px
      background-color: $white
      padding: 20px
      .delivery-volume
        display: flex
        margin-bottom: 10px
        .delivery-volume-item:first-child
          padding-right: 15px
        .delivery-volume-item:last-child
          padding-left: 15px
          border-right: none
        .label
          font-size: 12px
        .value
          font-weight: 600
      .delivery-volume-item
        border-right: 1px solid $light-grey
      .total-price
        .d-flex
          margin-bottom: 10px
        .d-flex:last-child
          margin-bottom: 15px
      .btn-usual
        display: block
        padding-bottom: 20px
        margin-bottom: 20px
        font-size: 12px
        text-decoration: none 
        border-bottom: 1px solid $light-grey
      .btn-usual:hover
        background-color: $white
      .btn-lg
        display: block
        text-align: center
        margin-bottom: 15px
        font-weight: bold
      .btn-lg:hover
        color: $white
        text-decoration: none
      .btn-createComplaint
        padding: 0
        border: none
        font-size: 14px
        margin: auto
        text-align: center
      .btn-createComplaint:focus
        background: none
  .rs-panel-collapsible
    border: none
    background-color: $white
    border-radius: 0
    width: calc( 100% - 330px)
    background-image: $edit no-repeat 0 center
    span
      font-size: 18px
      font-weight: 600!important
      color: $black
    svg
      display: none
  .rs-panel-header::before
    content: ''
    display: inline-block
    position: absolute
    right: 26px
    top: 26px
    background-image: $triangle-forward
    width: 8px
    height: 12px
    background-repeat: no-repeat
    transform: rotate(90deg)
  .rs-anim-collapse 
    color: $black
        
      

      
@media screen and (max-width: 1440px)
  .my-order-single-wrapper
    main
      margin: 30px 20px 50px 20px

@media screen and (max-width: 1024px)
  .my-order-single-wrapper
    .table-orders
      .product-info
        margin: 0
      .rs-flex-box-grid-top
        width: 100%
      .product-actions
        margin-bottom: 10px

@media screen and (max-width: 860px)
  .my-order-single-wrapper
    main
      margin: 15px 10px 30px 10px
    .my-order-single-download
      width: 100%!important
      margin: 15px 0!important
    .info-order-wrapper
      padding: 20px
      margin-bottom: 30px

      .col-1,
      .col-2
        width: 100%
    .rs-flex-box-grid-top
      width: 100%!important
      margin-bottom: 15px
    .order-details-wrapper
      flex-direction: column-reverse
      .table-orders
        width: 100%
        margin: 0
      .price-wrapper
        width: 100%
        margin-bottom: 20px
    .rs-panel-collapsible
      width: 100%
      

