@import "./assets/sass/variables"
body 
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 14px


* 
  -webkit-box-sizing: border-box
  box-sizing: border-box


html 
  font-family: sans-serif
  -ms-text-size-adjust: 100%
  -webkit-text-size-adjust: 100%


body, figure 
  margin: 0

html, body
  width: 100%
  height: 100%

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
  -webkit-box-shadow: 0 0 0 30px #FBF8F7 inset !important

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary 
  display: block


*:focus 
  outline: none


a 
  text-decoration: none


a:active,
a:hover 
  outline: 0


small 
  font-size: 80%


img 
  border: 0


address 
  font-style: normal


button,
input,
optgroup,
textarea 
  color: inherit
  font: inherit
  margin: 0
  -webkit-appearance: none


textarea 
  overflow: auto
  resize: none


table 
  border-collapse: collapse
  border-spacing: 0


ul, ol 
  margin: 0
  padding-left: 0
  list-style-type: none

ol.actuallyshowthelist
  list-style-type: decimal
  padding-left: 20px

ol.actuallyshowthelist > li
  padding-top: 10px


td, th 
  padding: 0


p 
  margin: 0


input[type=search]::-ms-clear 
  display: none
  width: 0
  height: 0


input[type=search]::-ms-reveal 
  display: none
  width: 0
  height: 0


input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration 
  display: none


input::-ms-clear 
  display: none

button
  background: transparent
  border: none

