@import "../../assets/sass/variables"
.side-menu-category
  background: $white
  height: calc(100% - 60px)
  position: fixed
  z-index: 12
  padding: 23px 0
  a,
  .no-category
    display: block
  .no-category:hover
    cursor: pointer
  .category-menu
    background: $white
    left: 25vw
    width: 25vw
    top: 0
    .category-menu
      background: $light-grey-2
      opacity: 0
      display: none
    .child-2
      background: $white
      border-left: 3px solid transparent
      left: calc(25vw - 3px)
    li:hover
      >div:not(.no-category)
        animation: show-menu .4s ease-in
        display: block
        opacity: 1
        z-index: 2
    div:first-child
      position: static
  .category-menu
      font-size: 18px
      font-weight: bold
      a,
      .no-category
        color: $black

      a:active,
      .no-category:active
        color: $blue-pressed
        text-decoration: none
  .category-menu.child-0
    >ul
      display: flex
      flex-direction: column
      .bicycles
        order: 1
      .accessories
        order: 2
      .parts
        order: 3
      li
        order: 10
  .category-menu:not(.child-0)
    >ul
      height: calc(100vh - 107px)
      overflow-y: auto
  .child-0
    margin-bottom: 23px
    .category-menu
      position: absolute
      a:hover
        text-decoration: none
    .accessories,
    .parts,
    .bicycles
      >span
        >a::before,
        >.no-category::before
          background-repeat: no-repeat
          content: ''
          display: inline-block
          width: 32px
          height: 32px
          vertical-align: middle
    .bicycles
      a::before,
      no-category::before
        background-image: $bike
    .accessories
      a::before,
      .no-category::before
        background-image: $accessories
    .parts
      a::before,
      .no-category::before
        background-image: $parts

@keyframes show-menu
  0%
    opacity: 0
  100%
    opacity: 1
.child-li-0
  padding: 7px 20px
  span
    span
      padding-left: 15px
      vertical-align: middle
.child-li-0:hover,
.child-li-0:active,
.child-li-0:focus
  >a,
  >.no-category
    color: $blue-hover
    text-decoration: none
.bicycles:hover,
.bicycles:active
  >a::before,
  >.no-category::before
    background-image: $bike-blue
  >a,
  >.no-category
    color: $blue-hover
    text-decoration: none
.accessories:hover,
.accessories:active
  a::before,
  .no-category::before
    background-image: $accessories-blue
  >a,
  >.no-category
    color: $blue-hover
    text-decoration: none
.parts:hover,
.parts:active
  >a::before,
  >.no-category::before
    background-image: $parts-blue
  >a,
  >.no-category
    color: $blue-hover
    text-decoration: none
li[class*='child-li-']:not(.child-li-0):hover
  >a,
  >.no-category
    color: $blue-hover
    text-decoration: none
.category-list-menu>.category-menu>li
  margin-bottom: 15px
  padding: 0 20px

.has-children.child-li-0
  >a,
  >.no-category
    background-position: right center

.has-children
  .has-button
    display: flex
    justify-content: space-between
    a
      flex: 1
  .innerCategory
    background: $triangle-forward no-repeat center center
    height: 32px
    width: 40px
  >a,
  >.no-category
    width: 100%
  li
    padding: 0 30px
  .category-menu
    padding: 23px 0
    a,
    .no-category
      font-size: 14px
      font-weight: normal
      padding: 7px 0
      span
        padding-left: 0
        line-height: 17px
    a::after,
    .no-category::after
      height: 17px

.recent-list_category
  width: 25vw
  h3
    padding: 30px 0 15px
    margin: 0 20px
    color: $black
    font-size: 18px
    font-weight: 600
    line-height: 22px
    border-top: 1px solid $light-grey-3
  li
    margin-bottom: 10px
    padding: 0 20px
  a,
  .no-category
    color: $black
    font-size: 14px
    font-weight: normal
  a:hover,
  .no-category:hover
    color: $blue-hover
    text-decoration: none
  a:active,
  .no-category:active
    color: $blue-pressed
    text-decoration: none
@media screen and (min-width: 860px)
    .category-menu
      .has-children
        >span
          >a
            background: $triangle-forward no-repeat right center
@media screen and (max-width: 860px)
  .child-li-0
    padding: 7px 10px
    .has-button
      padding: 0
  .recent-list_category
    h3
      margin: 10px
      padding-top: 25px
      margin-bottom: 0
    li
      padding: 0 10px
  .side-menu-category
    top: 0
    height: calc(100% - 60px)
    padding: 15px 0
    z-index: 101
    
    .category-menu
      width: 100vw
      background-color: $white!important
      li:first-child
        padding-top: 15px
      li:hover
        >div:not(.no-category)
          animation: none
          display: none
          left: 0
          opacity: 0
          z-index: 0
      li.active
        >div:not(.no-category)
          animation: show-menu .4s ease-in
          display: block
          left: 0
          opacity: 1
          z-index: 2
    .category-menu>div
      display: flex
      justify-content: space-between
      margin: 0 10px
      padding-bottom: 15px
      border-bottom: 1px solid $border-grey
      .btn
        font-size: 14px
        font-weight: normal
      .btn:hover
        background-color: transparent
      .btn:focus
        background-color: transparent

    .recent-list_category
      width: 100vw
    .parent-category-name
      padding-left: 25px
      color: $black
      background: $triangle-back no-repeat left center
    .has-children
      li
        padding: 0 10px
        a
          color: $black
        a:hover
          color: $blue-hover
          text-decoration: none
        a:active
          color: $blue-pressed
          text-decoration: none
        a:focus
          color: $blue-pressed
          text-decoration: none

