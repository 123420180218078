@import "../../assets/sass/variables"
.product-wrapper
  overflow: hidden
  padding: 20px
  margin-bottom: 45px
  >div
    float: left
#properties
  .table-responsive
    height: auto
.properties-wrapper  
  .btn-usual
    padding: 0
    margin-top: 15px
  margin: 0 auto
  max-width: 1000px
  .product-name
    font-size: 18px
    line-height: 22px
    font-weight: 600
    color: $dark-grey
    padding: 15px 0 20px 0

.product_info-wrapper
  width: 65%
  h1
    font-size: 24px
    font-weight: 600
    color: $black
    margin-bottom: 10px
  .subscription-wrapper 
    margin-bottom: 20px
    .btn-usual
      font-size: 12px
      line-height: 18px
  .product_info
    width: 100%
    justify-content: space-between
    >div
      flex: 1 1 auto
    .rs-flex-box-grid-item-0
      display: block
    .title
      display: flex
      width: 100%
      background: $light-blue
      height: 43px
      padding-left: 8px
      align-items: center
      font-size: 11px
      line-height: 13px
      color: $black
      justify-content: center
    .value
      display: flex
      flex-wrap: wrap
      background: $light-grey-2
      height: 54px
      padding-left: 8px
      font-size: 12px
      line-height: 15px
      align-items: center
      align-content: center
      color: $black
      justify-content: center
      text-align: center
      span
        width: 100%
        color: $dark-grey
    .vendorCode-wrapper
      .value
        color: $blue
        justify-content: left
      .title
        justify-content: left
    .toReserve-wrapper, .toCart-wrapper
      width: 94px
      flex: 0 1 auto
    .wrapperCounter
      padding: 0
      width: 74px
    .compare-wrapper
      width: 54px
      flex: 0 1 auto
      .compare
        margin: 10px
      .compare:disabled
        background: $compare-grey no-repeat left 50%

@media screen and (max-width: 860px)
  .product-wrapper
    flex-wrap: wrap
    padding: 10px
    margin-right: -10px
    margin-left: -10px
  .product_info-wrapper
    width: 100%
    .show-grid
      .rs-flex-box-grid-item
        height: 50px
        padding: 8px
        .title
          font-size: 10px
          color: $dark-grey
          height: 15px
          padding: 0
        .value
          height: 15px
          padding: 0
          span
            width: auto
            margin-left: 5px
          .btn-usual
            padding: 0
        .wrapperCounter
          height: 20px
      .compare-wrapper
        width: 33px!important
        padding: 7px
        .compare
          margin: 0
      .rs-flex-box-grid-item:nth-child(-n+5)
        background-color: $light-grey-2
        .title
          background-color: $light-grey-2
      .rs-flex-box-grid-item:nth-child(n+6)
        background-color: $light-blue
        .value, .wrapperCounter, .wrapperCounter input
          background-color: $light-blue
      .vendorCode-wrapper
        width: 25%
        .value
          white-space: nowrap
      .rs-flex-box-grid-item:nth-child(2)
        width: 25%
        .value
          white-space: nowrap
      .rs-flex-box-grid-item:nth-child(3)
        width: 20%
      .rs-flex-box-grid-item:nth-child(4)
        width: 15%
      .rs-flex-box-grid-item:nth-child(5)
        width: 15%
      .rs-flex-box-grid-item:nth-child(6)
        width: 26%
      .rs-flex-box-grid-item:nth-child(7)
        width: 26%
      .rs-flex-box-grid-item:nth-child(8)
        width: 19%
      .rs-flex-box-grid-item:nth-child(9)
        width: 19%

@media screen and (max-width: 500px)
    .product_info-wrapper
      .show-grid
        .rs-flex-box-grid-item
          height: auto
          .value
            height: auto
    

.property-list
  font-size: 14px
  line-height: 20px
  padding-bottom: 5px
  margin-bottom: 0
  li
    margin-bottom: 10px
    color: $black
    .property-name
      display: inline-block
      color: $dark-grey
    .property-name::first-letter
      text-transform: uppercase
.description
  font-size: 14px
  color: $black
  overflow: hidden
  max-height: 58px
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 3
  white-space: pre-wrap

h2
  margin-top: 45px
  font-size: 24px
  line-height: 29px
  font-weight: 600
  color: $black


.productInfo-properties-wrapper
  margin: 0 auto
  position: relative
  .productInfo-properties-list
    max-height: 300px
    overflow: hidden
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    li
      display: flex
      width: calc( 50% - 5px )
      min-height: 60px
      padding: 10px
      color: $black
      font-size: 14px
      line-height: 16px
      align-items: center
      .property-name
        width: 35%!important
        padding-left: 15px
        padding-right: 10px 
        margin-right: 5px
        min-width: 35%
        color: $dark-grey
        align-items: center
        overflow: hidden
      .property-name:first-letter
        text-transform: uppercase

    li:nth-child(4n+1)
      background: $light-grey
    li:nth-child(4n+2)
      background: $light-grey

.productInfo-properties-list::after
   content: ""
   display: block
   height: 100px
   width: 100%
   position: absolute
   bottom: 0
   background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #F8F8F8 90%)

.productInfo-description
  position: relative
  overflow: hidden
  max-height: 158px
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 8
  //white-space: pre-wrap
  ul
    margin-top: 20px
    padding-left: 20px
  li
    list-style: disc
    margin-bottom: 10px

.productInfo-description::after
   content: ""
   display: block
   height: 100px
   width: 100%
   position: absolute
   bottom: 0
   background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #F8F8F8 90%)
.productInfo-description.show-all
  max-height: none
  overflow: visible
  -webkit-line-clamp: inherit
.productInfo-description.show-all::after
  display: none

.productInfo-properties-list.show-all
  max-height: 100%
.productInfo-properties-list.show-all::after
  display: none

.productInfo-description-wrapper
  font-size: 14px
  color: $black
  margin-bottom: 45px

    
.productInfo-similarProducts-wrapper
  h2
    margin-bottom: 20px
  .wrapperCounter
    padding: 0
    width: 74px

@media screen and (max-width: 860px)
.productInfo-similarProducts-wrapper
  .wrapperCounter
    background-color: $light-blue-2
    height: 20px
    input
      background-color: $light-blue-2



.list_menu_product-wrapper
  white-space: nowrap
  background-color: $white
  overflow-x: auto
  ul
    display: flex
    background-color: $white
    align-items: center
    height: 70px
    padding-left: 10px 
    margin: 0
    li
      display: flex
      align-items: center
      height: 30px
      margin: 20px 10px 20px 10px
      font-size: 16px
      button
        color: $blue
      button:hover
        color: $blue-hover  
      button:active
        color: $blue-pressed

    li:first-child
      button
        color: $black
      button:hover
        color: $blue-hover  
      button:active
        color: $blue-pressed
    li::after
      box-sizing: border-box
      content: ''
      display: inline-block
      position: relative
      left: 10px
      height: 30px
      width: 26px
      background-image: $slash
      background-repeat: no-repeat
      background-position: center
    li:last-child::after
      display: none

.product-count-table
  .btn-usual
    margin-top: 0




@media screen and (max-width: 1300px)
  .properties-wrapper
    width: 100%
    padding: 0
    margin: 0
@media screen and (max-width: 860px)
  .properties-wrapper
    width: 100%
    padding: 0
    margin: 0
  .productInfo-properties-list
    li
      width: 100%!important
  .productInfo-description-wrapper
    margin-bottom: 0

.modal-slider.rs-modal-sm
  width: 70vw
  max-height: 95vh
  .slider-main
    padding-bottom: 0
    img
      width: auto
      height: auto
      max-height: 80vh
      max-width: 100%
      margin: 0 auto
  .slider-thumb_wrapper
    display: none
    .slick-list
      max-height: 100%
    img
      width: 100%
  .slider-main:after
    display: none
@media screen and (max-width: 860px)
  .modal-slider.rs-modal-sm
    width: 100vw
    max-height: 95vh
    .rs-modal-body
      padding: 0 10px