@import "../../../assets/sass/variables"
.create-return-page-wrapper
  .white-box>
    .input-wrapper
      padding: 0
      height: 100%
      .rs-input-group-btn
        padding-top: 20px
      .rs-input-group-btn:hover, .rs-input-group-btn:focus
        background-color: $light-grey-2
      .rs-input
        height: 40px
        padding-left: 40px!important
        background-color: $light-grey-2
      .search-results-wrapper
        max-height: 270px
        overflow-x: auto
        margin-bottom: 20px
      .reclamation-vendorcode-search
        .rs-input
          padding-top: 10px !important
        .rs-input-group-btn
          padding-top: 15px
  h4
    margin-bottom: 15px
    font-size: 18px
  .product-return-item
    border-bottom: 1px solid $border-grey
  .rs-uploader~div
    margin: 10px 0 20px 0
    font-size: 13px
    color: $dark-grey
  .shipping-recipient~.btn-lg
    margin-top: 25px

  .contact-data-wrapper, .shipping_wrapper
    max-width: 660px
  .contact-data
    margin-bottom: 50px
  .contact-data-header
    h3
      margin-bottom: 0
    .btn-edit
      margin: 0
  .rs-input-group 
    width: 100%!important
    max-width: 100%
  .input-wrapper
    width: 100%!important
    max-width: 660px
  .rs-input
    padding-top: 17px!important
  .search-shipping-wrapper
    .rs-input
      padding-top: 7px!important
  .pay-method-info>
    p
      padding-top: 20px
  .btn
    max-width: 320px

.create-return-page-wrapper .page-title>div>
  h3
    margin-bottom: 20px
    font-size: 24px
    font-weight: 600
    color: $black



@media screen and (max-width: 860px)
  .create-return-page-wrapper
    .white-box
        padding: 20px
        .rs-input
          margin-bottom: 0 
// http://localhost:3000/reclamation/13
.delivery-info
  display: flex
  flex-wrap: wrap
  margin-bottom: 20px
  .delivery-contact-info, .delivery-info-address
    width: 50%
    .key-value-info
      width: 100%
      margin-bottom: 20px
  .delivery-info-address
    .label
      color: $dark-grey
      font-size: 13px
      margin-bottom: 5px
  .delivery-type
    margin-bottom: 0
@media screen and (max-width: 860px)
  .delivery-contact-info, .delivery-info-address
    width: 100%!important

