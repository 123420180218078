@import "../../assets/sass/variables"
.header-wrapper
  background-color: $white
  width: 100%
  position: fixed
  top: 0
  left: 0
  z-index: 100
.overflow
  background: $black
  height: calc(100% - 60px)
  left: 0
  opacity: .5
  position: fixed
  top: 60px
  width: 100%
  z-index: 10
@media screen and (max-width: 860px)
  .overflow
    top: 0
header
  background: $white
  border-bottom: 1px solid $border-grey
  display: flex
  align-items: center
  height: 60px
  margin: 0 20px
  position: relative
  z-index: 101
.btn-catalog
  height: 100%
  font-size: 16px
  padding: 0 54px
  position: relative
  flex-basis: 264px
  flex-grow: 0
  flex-shrink: 0
  overflow: hidden
  white-space: nowrap
  .burger
    display: inline-block
    width: 25px
    height: 10px
    position: relative
    margin-right: 10px
  .burger::before,
  .burger::after
    content: ''
    display: block
    height: 1px
    width: 100%
    background: $white
    position: absolute
    left: 0
    -webkit-transition: .3s ease-in-out
    -moz-transition: .3s ease-in-out
    -o-transition: .3s ease-in-out
    transition: .3s ease-in-out
  ::before
    top: 0
  ::after
    bottom: 0
.open
  span
  ::before
    top: 3px
    left: 0
    transform: rotate(45deg)
  ::after
    transform: rotate(-45deg)
    top: 3px
    left: 0
.btn-catalog::before,
.btn-catalog::after
  content: ''
  display: block
  position: absolute
  top: 0
  width: 0
  height: 0
  border-style: solid
.btn-catalog::before
  border-width: 60px 54px 0 0
  border-color: $white transparent transparent transparent
  left: 0
.btn-catalog::after
  border-width: 0 0 60px 54px
  border-color: transparent transparent $white transparent
  right: 0
@media screen and (max-width: 960px)
  .btn-catalog
    flex-basis: 134px
  .catalog-text
    display: none
@media screen and (max-width: 860px)
  .btn-catalog
    background: $white
    border-top: 1px solid $light-grey
    color: $black
    padding: 17px 10px 0
    position: fixed
    bottom: 0
    left: 0
    flex-basis: 25%
    height: 60px
    width: 25%
    z-index: 100
    display: flex
    flex-direction: column
    text-align: center
    .burger
      align-self: center
      display: block
      margin-right: 0
    .burger::before,
    .burger::after
      background: $black
    .catalog-text
      align-self: center
      font-size: 12px
      line-height: 14px
      padding-top: 8px
  .btn-catalog::before,
  .btn-catalog::after
    display: none
  .btn-catalog:hover
    background: $white
  .catalog-text
    display: block
  .btn-catalog.open
    .burger::before,
    .burger::after
      background: $blue
    .catalog-text
      color: $blue
.search-wrapper
  display: flex
  align-items: center
  justify-content: space-between
  width: 132px
  height: 100%
  position: relative
.search-wrapper.active
  flex-basis: 60%
  flex-grow: 0
  flex-shrink: 0
  z-index: 15
  background: $white
  margin-right: auto
.search-wrapper::after
  content: ''
  display: block
  height: 100%
  flex-basis: 56px
  flex-grow: 0
  flex-shrink: 0
  background: url("data:image/svg+xml,%3Csvg width='58' height='60' viewBox='0 0 58 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' d='M1 59L57 -1' stroke='%231D1D1B'/%3E%3C/svg%3E%0A") no-repeat top right
  transform-origin: 0 0 0
.search
  background: url(../../assets/img/search.svg) no-repeat left 50%
  background-size: 20px
  font-size: 14px
  padding-left: 30px
  height: 100%
  width: 100%

@media screen and (min-width: 860px)
  .hideDesktopSearch
    display: none

@media screen and (max-width: 860px)
  .search-wrapper
    display: block
    background: $white
    border-top: 1px solid $light-grey
    bottom: 0
    height: 60px
    left: 50%
    position: fixed
    width: 25%
    z-index: 100
  .search-wrapper::after
    display: none
  .search
    background: $light-grey-2
    height: 40px
  .openSearch
    background: url(../../assets/img/search.svg) no-repeat top 10px center
    background-size: 24px 24px
    color: $black
    font-size: 12px
    height: 100%
    text-align: center
    padding: 34px 10px 10px
    width: 100%
  .search-wrapper.active
    .autocomplete-wrapper
      display: block
    .openSearch
      background-image: url(../../assets/img/search-blue.svg)
      color: $blue
header
  .rs-dropdown-toggle.rs-btn
    background: transparent $drop-down-list no-repeat right 5px top 15px
    color: $black
    padding: 8px 22px 8px 8px
  .rs-dropdown-toggle-caret
    display: none
.menu
  margin: 0 0 0 auto
  li
    display: inline-block
    margin-right: 10px
  li:last-child
    margin-right: 0
  a
    color: $black
    padding: 0 5px
    white-space: nowrap
  a:hover
    color: $blue-hover
    text-decoration: none
  a:active,
  a.active
    color: $blue-pressed
    text-decoration: none
  a:disabled
    opacity: 0.5
    text-decoration: none
  .admin-menu
    display: inline !important
    li
      margin-right: 0
      width: 100%
.menu.admin
  li
    padding: 0
    margin-right: 0
    width: 100%
    a
      display: block
      padding: 10px
@media screen and (max-width: 860px)
  header
    .menu
      background: $white
      border-top: 1px solid $light-grey
      bottom: 0
      height: 60px
      left: 25%
      position: fixed
      width: 25%
      z-index: 100
    .openProfile
      background: transparent $profile-black no-repeat top 10px center
      background-size: 24px 24px
      height: 60px
      font-size: 12px
      padding: 34px 10px 10px
      width: 100%
    .openProfile.open
      background-image: $profile
      color: $blue
    .nav-desktop
      box-shadow: none
      border-radius: 0
      position: fixed
      height: calc(100% - 60px)
      left: 0
      top: 0
      width: 75vw
      max-width: 75%
      z-index: 11
  .lang
    margin-left: auto
.exchange_rate
  background: $light-blue
  border-radius: 5px
  color: $dark-grey
  font-size: 11px
  padding: 10px
  margin-left: 15px
  white-space: nowrap
  span
    color: $black
    font-weight: 600
.compare
  background: $compare no-repeat left 50%
  background-size: 18px
  height: 22px
  min-width: 18px
  margin-left: 15px
  padding-left: 26px
.cart
  background: $cart-url_active no-repeat left 50%
  background-size: 18px
  height: 22px
  min-width: 18px
  margin-left: 15px
  padding-left: 26px
  position: relative
.total-products
  display: inline-block
  position: absolute
  top: -3px
  right: -3px
  background: $red
  color: $white
  font-size: 11px
  height: 15px
  min-width: 15px
  padding: 0 5px
  border-radius: 50%
  text-align: center
  line-height: 16px
.profile
  background: $profile no-repeat left 50%
  background-size: 18px
  margin-left: 15px
  padding-left: 22px

@media screen and (min-width: 1400px)
  .hideDesktop
    display: none
  .menu.admin
    .hideDesktop
      display: block
    .openProfile
      background: transparent $drop-down-list no-repeat right 5px top 15px
      color: $black
      padding: 8px 22px 8px 8px
    .nav-desktop
      background: $white
      box-shadow: rgba(0, 0, 0, 0.06) 0 0 10px 0, rgba(0, 0, 0, 0.12) 0 4px 4px 0
      border-radius: 6px
      padding: 6px 0
      position: absolute
      max-width: 200px
      z-index: 10
    a
      display: block
      padding: 8px 12px
      clear: both
      font-weight: normal
      line-height: 1.42857143
      white-space: nowrap
      cursor: pointer
      text-decoration: none
@media screen and (max-width: 1400px)
  .profile
    display: none
  .openProfile
    background: transparent $drop-down-list no-repeat right 5px top 15px
    color: $black
    padding: 8px 22px 8px 8px
  .hideDesktop
    display: block
  .nav-desktop
    background: $white
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 10px 0, rgba(0, 0, 0, 0.12) 0 4px 4px 0
    border-radius: 6px
    padding: 6px 0
    position: absolute
    max-width: 200px
    z-index: 10
  .menu
    a
      display: block
      padding: 8px 12px
      clear: both
      font-weight: normal
      line-height: 1.42857143
      white-space: nowrap
      cursor: pointer
      text-decoration: none
    .admin-menu
      display: none !important
@media screen and (min-width: 860px)
  .hideDesktopCart
    display: none
.openCart
  display: block
  background: $white $cart-url no-repeat top 10px center
  background-size: 24px 24px
  border-top: 1px solid $light-grey
  bottom: 0
  color: #1D1D1B
  font-size: 12px
  height: 60px
  text-align: center
  padding: 34px 10px 10px
  position: fixed
  right: 0
  width: 25%
  z-index: 1
  .basket-name
    display: block
.openCart.open
  background-image: $cart-url_active
  color: $blue

@media screen and (max-width: 860px)
  .openCart
    z-index: 101
@media screen and (max-width: 425px)
  header
    margin: 0 10px
  .exchange_rate
    margin-left: 10px
  .logo
    width: 110px
    img
      height: auto
      width: 100%