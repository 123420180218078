@import "../../../assets/sass/variables"
.created-return-page
  .table-responsive
    height: auto
    margin-bottom: 30px
  h2
    padding: 15px 0 20px 0
  .white-box
    h3
      font-size: 18px
      font-weight: 600
      color: $black
      line-height: 22px
      margin-bottom: 15px
    h2
      padding: 0 0 15px 0
  .return-status
    position: relative
    display: flex
    justify-content: space-between
    padding: 40px
    text-transform: none
    font-weight: normal
    background-color: $white
    height: 133px
    .created-point
      max-width: 56px
    .created-point, .looking-point, .closed-point
      font-size: 14px
      color: $black!important
    .created-point, .looking-point, .closed-point
      display: flex
      flex-direction: column
      justify-content: flex-end
    .looking-point
      align-items: center


    .created-point::after
      content: ''
      display: block
      width: calc( 50% - 100px ) 
      height: 2px
      vertical-align: middle
      position: absolute
      left: 80px
      top: 49px
      background-color: $light-grey
    .closed-point::after
      content: ''
      display: block
      width: calc( 50% - 106px ) 
      height: 2px
      vertical-align: middle
      position: absolute
      right: 90px
      top: 49px
      background-color: $light-grey



    .closed-point::before, .looking-point::before, .created-point::before
      content: ''
      display: inline-block
      width: 22px
      height: 22px
      vertical-align: middle
      position: relative
      bottom: 12px
      border: 7px solid $light-grey
      border-radius: 50%
    .closed-point::before, .created-point::before
      left: 15px
    .looking-point::before
      left: -2px

    
  .status-0
    .created-point::before
      content: ''
      display: inline-block
      width: 26px
      height: 26px
      vertical-align: middle
      position: relative
      left: 10px
      bottom: 10px
      background-image: $return-status
      background-repeat: no-repeat
      border: 0
  .status-1
    .looking-point::before, .created-point::before
      content: ''
      display: inline-block
      width: 26px
      height: 26px
      vertical-align: middle
      position: relative
      bottom: 10px
      background-image: $return-status
      background-repeat: no-repeat
      border: 0
    .created-point::before
      left: 10px
    .created-point::after
      background-color: $green
  .status-2
    .created-point::before, .looking-point::before, .closed-point::before
      content: ''
      display: inline-block
      width: 26px
      height: 26px
      vertical-align: middle
      position: relative      
      bottom: 10px
      background-image: $return-status
      background-repeat: no-repeat
      border: 0
    .created-point::after
      background-color: $green
    .closed-point::after
      background-color: $green
    .created-point::before, .closed-point::before
      left: 10px

  .decision-info
    display: flex
    flex-wrap: wrap
    margin-bottom: 20px
    .key-value-info
      width: 50%
      margin-bottom: 20px 
  .title
    font-size: 13px
    color: $dark-grey
    margin-bottom: 5px
  p
    font-size: 14px
    color: $black
  .table-responsive
    .rs-table-body-row-wrapper
      .rs-table-row
        cursor: pointer
    .rs-table-cell-content
      color: $black
    .rs-table-cell-wrap
      font-size: 12px
      font-weight: normal
      color: $blue!important
    .rs-table-cell-last
      .rs-table-cell-wrap
        color: $black!important
.created-return-page
  .white-box
    h3
      margin-bottom: 20px

    


@media screen and (max-width: 860px)
  .created-return-page
    .key-value-info
      width: 100%!important

    .return-status
      padding: 20px
      height: 93px
      .created-point::after
        width: calc( 50% - 80px ) 
        left: 60px
        top: 29px
      .closed-point::after
        width: calc( 50% - 86px ) 
        right: 70px
        top: 29px

