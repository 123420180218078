@import "../../assets/sass/variables"

.product-page
  main
    display: flex
    justify-content: space-between
    position: relative
    .basket-wrapper
      position: sticky
      top: 60px
  .inner-block
    background: $light-grey-2
    flex-grow: 1
    padding: 20px
    overflow-y: auto
@media screen and (max-width: 860px)
  .product-page
    .inner-block
      padding: 10px 10px 80px 10px
      position: relative
    main
      .basket-wrapper
        position: fixed
