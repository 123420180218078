@import "../../assets/sass/variables"
.my-managers-page-wrapper
  background-color: $light-grey-2
  main
    min-height: 100vh
    height: auto
    max-width: 1400px
    margin: 30px auto 50px auto
    overflow-x: auto 
    h2
      margin: 0 0 20px 0
    .me-manager-wrapper
      padding: 40px
      background: $white
      margin-bottom: 10px
      h3
        font-size: 18px
        font-weight: 600
        color: $black
        margin-bottom: 15px
      .manager-wrapper-info
        margin: 0 0 15px
        width: 22%
      .info-manager-item
        display: flex
        flex: 1 1 auto
        a
          margin-right: 15px

    .me-manager-wrapper>div
      display: flex
      flex-wrap: wrap
             
    .btn-email
      background-image: $message
      height: 38px
      width: 38px
    .btn-phone
      background-image: $phone
      height: 38px
      width: 38px
  
    .question-wrapper
      display: flex
      flex-wrap: wrap
      align-items: center
      background-image: $back-menedger
      background-repeat: no-repeat
      background-size: 100%
      height: 145px!important
      margin-top: 40px
      .btn-lg
        width: 200px
        height: 45px
        font-size: 14px
        font-weight: bold
        color: $white
        background: $blue
        border-radius: 0
      .btn-lg:hover
        background: $blue-hover
        cursor: pointer
      .btn-lg:active
        background: $blue-pressed
      .btn-lg:disabled
        background: $blue-disabled

    .question-wrapper>div
      font-size: 24px
      font-weight: 600
      color: $white
      margin-right: 40px
      margin-left: 20%
.modal-complain
  .rs-input
    margin-bottom: 0 !important
  .rs-modal-content
    .btn
      margin-top: 0
  .complain-msg
    height: 90px
.rs-modal-wrapper
  .rs-picker-select-menu-item-active
    color: $black!important


@media screen and (max-width: 1440px)
  .my-managers-page-wrapper
    main
      margin: 30px 20px 50px 20px

@media screen and (max-width: 1250px)
  .my-managers-page-wrapper
    .question-wrapper
      background-size: auto!important
    .manager-wrapper-info
      width: 25%

@media screen and (max-width: 860px)
  .my-managers-page-wrapper
    main
      margin: 15px 10px 90px 10px
    .question-wrapper
      background-size: auto!important
      background-position-x: -365px
      align-content: center
      .btn-lg
        margin-left: 45px!important
    .question-wrapper>div
      width: 100%
      margin-left: 45px!important 
      margin-bottom: 15px  
      font-size: 20px!important   
    .me-manager-wrapper
      padding: 20px!important
    .me-manager-wrapper>div
      flex-direction: column
      .manager-wrapper-info
        width: 100%

      


