@import "../../../assets/sass/variables"
.create-return-page-wrapper
    h2
      padding: 15px 0 20px 0
    .white-box
      h3
        font-size: 18px
        font-weight: 600
        color: $black
        line-height: 22px
        margin-bottom: 15px
      h2
        padding: 0 0 15px 0
      p
        color: $black
      .text-description
        padding-top: 10px
        margin: 0
        li
          padding-bottom: 10px 
          color: $black
          padding-left: 26px
          position: relative

        li::before
          box-sizing: border-box
          content: ''
          display: block
          height: 6px
          width: 6px
          border: $blue 4px solid
          position: absolute
          left: 0
          top: 7px
      .toggle-difference
        margin-bottom: 25px
        padding-top: 5px
        color: $black
        .comparison-difference 
          padding-right: 10px
      .product-return-item
        max-width: 654px
        min-height: 54px
        padding: 5px  0
        display: flex
        align-items: center
        font-size: 12px
        color: $black
        border-top: 1px solid $border-grey
        .product-return-code
          padding: 0 8px 
          white-space: nowrap
        .product-return-name
          overflow: hidden
          padding: 0 8px
        .wrapperCounter, input
          background-color: $white
        .product-return-count
          margin-left: auto 
      .product-return-item:last-child
        border-bottom: 1px solid $border-grey
        margin-bottom: 20px
      .product-return-comment
        max-width: 654px
        margin-bottom: 25px
        position: relative
        .rs-input
          padding: 8px 15px
          border: none
          font-size: 12px
          color: $black
          background-color: $light-grey-2
      .product-return-btn
        max-width: 654px
    
    .white-box
      color: $black
      .input-wrapper
        margin-top: 10px

    .input-wrapper
      height: 40px
      //padding: 3px 15px
      max-width: 420px
      label
        padding-top: 3px
        line-height: 12px
      .rs-btn
        //padding: 0 0 3px 0!important
        line-height: 15px
      .rs-btn:after
        //top: 0!important
.uploader-photo
  position: relative

      



