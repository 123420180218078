@import "../../assets/sass/variables"
.table-wrapper
  display: block
  position: relative
  width: 100%
  .vendorCode
    padding-left: 10px
    color: $blue
.table-responsive
  max-height: calc(100vh - 265px)
  height: calc(100vh - 265px)
  display: block
  width: 100%
  position: relative
  overflow: auto
  table
    min-width: 1000px
    width: 100%
    max-width: 100%
    th
      white-space: nowrap
    .rrc,
    .price,
    .totalPrice,
    .income,
    .available,
    .toReserve,
    .toCart,
    .lastPurchase,
    .quantityPerBox
      white-space: nowrap
      text-align: center
    .rrc,
    .price
      white-space: pre-wrap
      min-width: 120px
  table.mobile
    min-width: 100%
    width: 100%
    max-width: 100%
  .invoice_number_wrapper:after
    content: ''
    display: inline-block
    width: 18px
    height: 18px
    vertical-align: middle
    position: relative
    left: 5px
    bottom: 2px
    background-image: $TTH
    background-repeat: no-repeat
@media screen and (max-width: 860px)
  .table-responsive
    height: 100%

.table-mobile-view
  .title
    color: $dark-grey
    font-size: 10px
    margin-bottom: 5px
.product-item
  color: $black
  font-size: 12px
  margin-top: 10px
  >div
    padding: 10px
  .product-info
    -webkit-flex-wrap: nowrap
    flex-wrap: nowrap
    margin-left: -5px
    margin-right: -5px
  .product-info-vendorCode,
  .product-info-image
    -webkit-flex: 0 0 auto
    flex: 0 0 auto
  .product-info-vendorCode
    width: 20%
  .product-info-name
    -webkit-flex: 1 1 auto
    flex: 1 1 auto
  .vendorCode
    padding: 0
  .bold
    font-weight: 600
.product-item:first-child
  margin-top: 0
.product-info-image
  margin: 0 10px
  width: 12%
  img
    height: auto
    width: 100%
.product-info-name
  a
    color: $blue
    overflow: hidden
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    white-space: pre-wrap
  a:hover
    color: $blue-hover
    text-decoration: none

  a:active
    color: $blue-pressed
    text-decoration: none

  a:disabled
    color: $blue-disabled
  a:focus
    text-decoration: none

  
.product-info
  background: $white
.product-actions
  background: $light-blue-2
  align-items: center
  >div
    flex: 1 1 auto
  .compare-wrapper
    flex: 0 1 34px
  .compare:disabled
    background: $compare-grey no-repeat left 50%
  .btn-usual
    padding: 0
.table_settings-wrapper
  .checkbox
    display: block
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    line-height: 18px
  .checkbox::before
    float: left

