@import "../../assets/sass/variables"
.generic-btn
  color: $blue
.generic-btn:hover
  color: $blue-hover
  text-decoration: none
.generic-btn:active,
.generic-btn:focus
  color: $blue-pressed
  text-decoration: none
.generic-btn:focus
  text-decoration: none
.btn-edit
  margin-bottom: 15px
  padding: 0 0 0 28px
  background: $edit no-repeat 0 center
.btn-save
  background-image: none
  padding-left: 0
.btn-delete
  background: $basket no-repeat
  width: 18px
  height: 18px
.btn-topUpBalance
  background: $balance no-repeat 0 center
  padding-left: 20px
